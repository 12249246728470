import '../../components/NavBar/StyleWaitAMinute.css';
import {useTranslation} from 'react-i18next';

function BtnComponent(props){
    const [t] = useTranslation("global");
    return(
        <div>
            {(props.status === 0)? 
             <button className="btn-watch"
             onClick={props.start}>{t('header.Start')}</button> : ""
            }
            {(props.status === 1)? 
            <div>
             <button className="btn-watch"
             onClick={props.stop}>{t('header.Stop')}</button>
              <button className="btn-watch"
             onClick={props.reset}>{t('header.Reset')}</button>
             </div> : ""
            }
            {(props.status === 2)? 
            <div>
             <button className="btn-watch"
             onClick={props.resume}>{t('header.Resume')}</button>
              <button className="btn-watch"
             onClick={props.reset}>{t('header.Reset')}</button>
             </div> : ""
            }
        
        </div>
    );
}
export default BtnComponent;