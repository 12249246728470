import ImageTest from '../images/ImageTest.png';
import React, {useEffect} from 'react';
import ReactGa from 'react-ga';
import Cookies from 'universal-cookie';
import {useTranslation} from 'react-i18next';

const cookies = new Cookies();

const ChangeBattery = () => {
    useEffect(() => {
        ReactGa.initialize('UA-212298576-1')
        ReactGa.pageview(window.location.pathname)
        cookies.set('N5_07', window.location.pathname);
      }, [])
      const [t] = useTranslation("global");
    return (
        <div className="Pages">
            <h2>{t('header.The control units rechargeable battery is low and is recharging. The problem should be gone in the next 24 hours. If the problem persists beyond that, the battery needs to be changed.')}</h2>
            <div className="imagesPages"><img src={ImageTest} alt="imageTest"/></div>
        </div>
    )
}
export default ChangeBattery