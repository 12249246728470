import { NavBtnPages } from '../../components/NavBar/NavbarElements';
import ImageTest from '../images/ImageTest.png';
import React, {useEffect} from 'react';
import ReactGa from 'react-ga';
import Cookies from 'universal-cookie';
import {useTranslation} from 'react-i18next';

const cookies = new Cookies();

const ThisMeans = () => {
    useEffect(() => {
        ReactGa.initialize('UA-212298576-1')
        ReactGa.pageview(window.location.pathname)
        cookies.set('A2', window.location.pathname);
      }, [])
      const [t] = useTranslation("global");
    return (
        <div className="Pages">
            <h2>{t('header.This means that the Aqua-Protec system is inflated right now. You shall not evacuate water from any fixture or appliance since you may self-flood. If you press on the down arrow, the LCD screen should light up and tell you how much time if left before the deflation of the Aqua-Protec system')}</h2>
            <div className="imagesPages"><img src={ImageTest} alt="imageTest"/></div>
            <div className="bannerPages">
            <NavBtnPages to='/IUnderstand/thanks' onClick={cookies.set('N8_01', '/IUnderstand/thanks')}>{t('header.I Understand')}</NavBtnPages> 
            </div>
        </div>
    )
}
export default ThisMeans