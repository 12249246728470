import React from 'react';
import './Style.css';

const Footer = () => {
    return (
        <>
            <div className="waves">
                <div className="wave" id="wave1"></div>
                <div className="wave" id="wave2"></div>
                <div className="wave" id="wave3"></div>
                <div className="wave" id="wave4"></div>
            </div>
        </>
    );
}
export default Footer