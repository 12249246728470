import './Style.css';
import './StyleBtnHeader.css';
import { NavLink, NavLi} from './NavbarElements';
import logo from '../../imagesHeader/Logo1_120x300.png';
import { useHistory } from 'react-router';
import {useTranslation} from 'react-i18next';

 const Header = () => {
     const [t, i18n] = useTranslation("global");
     let history = useHistory();
    return (
        <>        
        <NavLink to="/">
            <div className="logo"><img src={logo} alt='logo'/></div> 
        </NavLink>
        
        <div className="translateArea">
        <button className="btnLangueEn" onClick={()=> i18n.changeLanguage("en")}>En</button>&nbsp;&nbsp;&nbsp;&nbsp;
        <button className="btnLangueFr" onClick={()=> i18n.changeLanguage("fr")}>Fr</button>
        </div>
        
        <div className="bannerHeader">  
            <NavLi to="/">
                <div className="btn"><a>{t('header.Home')}</a></div>
            </NavLi>
            <div className="title">{t('header.Troubleshooter')}</div>
            <div className="btn" onClick={history.goBack}><a>{t('header.Back')}</a></div> 
        </div>
        </>
    );
};
export default Header

