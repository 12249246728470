import { NavBtnPages } from '../../components/NavBar/NavbarElements';
import ImageTest from '../images/ImageTest.png';
import React, {useEffect} from 'react';
import ReactGa from 'react-ga';
import Cookies from 'universal-cookie';
import {useTranslation} from 'react-i18next';

const cookies = new Cookies();

const PressBackTwice = () => {
    useEffect(() => {
        ReactGa.initialize('UA-212298576-1')
        ReactGa.pageview(window.location.pathname)
        cookies.set('N4_05', window.location.pathname);
      }, [])
      const [t] = useTranslation("global");
    return (
        <div className="Pages">
            <h2>{t('header.You can now press on Back twice at the top left of your screen. Once you have clicked on Back twice you can now press on Done at the top left of the screen. You should now receive notifications.')}</h2>
            <div className="imagesPages"><img src={ImageTest} alt="imageTest"/></div>
            <div className="bannerPages">
            <NavBtnPages to='/Done/thanks' onClick={cookies.set('N8_01', '/Done/thanks')}>{t('header.Done')}</NavBtnPages> 
            </div>
        </div>
    )
}
export default PressBackTwice