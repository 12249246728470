import '../components/NavBar/Style.css';
import '../components/NavBar/StyleBtnHeader.css';
import { NavBtn,NavBtnLink} from '../components/NavBar/NavbarElements';
import ImageTest from './images/ImageTest.png';
import React, {useEffect} from 'react';
import ReactGa from 'react-ga';

const HomeSession = () => {
    useEffect(() => {
        ReactGa.initialize('UA-212298576-1')
        ReactGa.pageview(window.location.pathname)
      }, [])
    return (
        <div className="bannerPages">
            <NavBtn>
            <NavBtnLink to="/homeAquaProtect">
                Aqua Protect<div className="imagesHome"><img src={ImageTest} alt='ImageTest'/></div>
            </NavBtnLink>
            </NavBtn>
            <NavBtn>
            <NavBtnLink to="/homeNowa360">
                Nowa 360<div className="imagesHome"><img src={ImageTest} alt='ImageTest'/></div>
            </NavBtnLink>
            </NavBtn>
            <div className="bannerPages">
            </div>
        </div>
    );
};
export default HomeSession

