import styled from "styled-components";
import { NavLink as Link } from "react-router-dom";

//Header logo
export const NavLink = styled(Link)`
background: #fff;
display: flex;
flex-wrap: wrap;
margin: auto;
position: relative;
text-align: center;
align-items: center;
width: 100%;
cursor: pointer;

&.active {
    color: #15cdfc;  
  }
`

//boxes container
export const Nav = styled.nav`
background: #fff;
width: 100%;
display: flex;
flex-wrap: wrap;
text-align: center;
align-items: center;
`
//boxes ext photos
export const NavBtn = styled.nav`
height: 100%;
display: flex;
flex-wrap: wrap;
aling-items: center;
text-align: center;
margin: auto;
padding-top: 30px; 
@media screen and (max-width: 580px){
  {padding-top: 50px; }
}
`
export const NavBtnLin = styled(Link)`
width: 150px;
height: 50px;
text-align: center;
font-size: 20px;
background: #00B5F3;
padding: 12px 0px;
text-decoration: none;
color: #fff;
margin-top: 50px;
`
//boxes int photos
export const NavBtnLink = styled(Link)`
flex-wrap: wrap;
border-radius: 20px;
width: 400px;
height: 350px;
text-align: center;
background: #CCCCCC;
padding: 20px 20px;
color: #000;
font-size: 18px;
border: none;
outline: none;
cursor: pointer;
transition: all 0.2s ease-in-out;
text-decoration: none;

&:hover{
    transition: all 0.2s ease-in-out;
    background: gray;
    color: #fff;
}
`
export const NavLi = styled(Link)
`
`
export const NavBtnPages = styled(Link)`
width: 350px;
height: 40px;
text-align: center;
font-family: Arial;
font-size: 20px;
background: #00B5F3;
padding: 5px;
padding-top: 5px;
margin: 10px;
text-decoration: none;
color: #fff;
&:hover{
  transition: all 0.2s ease-in-out;
  background: #0088FF;
  color: #fff;
}
`
export const NavBtnPagesLongText = styled(Link)`
width: 350px;
height: 110px;
text-align: center;
font-family: Arial;
margin: 10px;
font-size: 20px;
background: #00B5F3;
padding: 10px;
text-decoration: none;
color: #fff;
&:hover{
  transition: all 0.2s ease-in-out;
  background: #0088FF;
  color: #fff;
}
`



