import { NavBtnPages } from '../../components/NavBar/NavbarElements';
import ImageTest from '../images/ImageTest.png';
import React, {useEffect} from 'react';
import ReactGa from 'react-ga';
import Cookies from 'universal-cookie';
import {useTranslation} from 'react-i18next';

const cookies = new Cookies();

const PowerSupplyBack = () => {
    useEffect(() => {
        ReactGa.initialize('UA-212298576-1')
        ReactGa.pageview(window.location.pathname)
        cookies.set('A4_24', window.location.pathname);
      }, [])
      const [t] = useTranslation("global");
    return (
        <div className="Pages">
            <h2>{t('header.When the power will come back, plug the power supply back. Also, since your battery is depleted, you will need to change the battery. You may change it yourself or call your installer to do it')}</h2>
            <div className="imagesPages"><img src={ImageTest} alt="imageTest"/></div>
            <div className="bannerPages"> 
                <NavBtnPages to='/Done/thanks' onClick={cookies.set('N8_01', '/Done/thanks')}>{t('header.Done')}</NavBtnPages>
            </div>
        </div>
    )
}
export default PowerSupplyBack