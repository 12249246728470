import { NavBtnPages } from '../../components/NavBar/NavbarElements';
import ImageTest from '../images/ImageTest.png';
import React, {useEffect} from 'react';
import ReactGa from 'react-ga';
import Cookies from 'universal-cookie';
import {useTranslation} from 'react-i18next';

const cookies = new Cookies();

const UnfortunateEvent = () => {
    useEffect(() => {
        ReactGa.initialize('UA-212298576-1')
        ReactGa.pageview(window.location.pathname)
        cookies.set('A7', window.location.pathname);
      }, [])
      const [t] = useTranslation("global");
    return (
        <div className="Pages">
            <h2>{t('header.This is an unfortunate event. Here are some questions that we will ask you to be able to understand the cause of this situation. Please answer these questions at the best you can and send them to this email address supptech@nowa360.com What was the color of the sewer backup water (Dirty, clean, soapy) Did the sewer backup came with a strong odor? At how much do you estimate the damages? Did you take pictures of damages done? Please include them in your email or use wetransfer.com to transfer thoses files. From where did the water come out? Do you know if you have comnied or seperated storm and sewer drain? Do you know if your neiborghood had some sewer backup as well? Do you have a sump-pump?')}</h2>
            <div className="imagesPages"><img src={ImageTest} alt="imageTest"/></div>
            <div className="bannerPages"> 
            <NavBtnPages to='/IUnderstand/thanks' onClick={cookies.set('N8_01', '/IUnderstand/thanks')}>{t('header.I Understand')}</NavBtnPages>
            </div>
        </div>
    )
}
export default UnfortunateEvent