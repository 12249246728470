import { NavBtnPages } from '../../components/NavBar/NavbarElements';
import ImageTest from '../images/ImageTest.png';
import React, {useEffect} from 'react';
import ReactGa from 'react-ga';
import Cookies from 'universal-cookie';
import {useTranslation} from 'react-i18next';

const cookies = new Cookies();

const DoingWeeklyTest = () => {
    useEffect(() => {
        ReactGa.initialize('UA-212298576-1')
        ReactGa.pageview(window.location.pathname)
        cookies.set('A4_00', window.location.pathname);
      }, [])
      const [t] = useTranslation("global");
    return (
        <div className="Pages">
            <h2>{t('header.The Aqua-Protec system is doing his weekly test. During his weekly test, all lights go off and the screen goes blank. After the test has been completed the panel should go back to a standby position. The test takes about 10 minutes')}</h2>
            <div className="imagesPages"><img src={ImageTest} alt="imageTest"/></div>
            <div className="bannerPages"> 
                <NavBtnPages to='/WaitForTenMiniutes/tenMinutesTimer'>{t('header.Wait for 10 minutes')}</NavBtnPages>
                <NavBtnPages to='/Done/thanks' onClick={cookies.set('N8_01', '/Done/thanks')}>{t('header.Done')}</NavBtnPages>
            </div>
        </div>
    )
}
export default DoingWeeklyTest