import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {I18nextProvider} from 'react-i18next';
import i18n from 'i18next';
import i18next from 'i18next';
import global_fr from './translations/fr/global.json';
import global_en from './translations/en/global.json';
import LanguageDetector from 'i18next-browser-languagedetector';


i18n.use(LanguageDetector).init({
  interpolation: {escapeValue: false},
  debug: true,
  fallbackLng: "en",
  resources: {
    fr: {
      global: global_fr
    },
    en: {
      global: global_en
    }
  }
});
ReactDOM.render(
  <I18nextProvider i18n={i18next}>
     <App />
  </I18nextProvider>,
  document.getElementById('root')
);


