import { NavBtnPages } from '../../components/NavBar/NavbarElements';
import ImageTest from '../images/ImageTest.png';
import React, {useEffect} from 'react';
import ReactGa from 'react-ga';
import Cookies from 'universal-cookie';
import {useTranslation} from 'react-i18next';

const cookies = new Cookies();

const PanelEmergencyState = () => {
    useEffect(() => {
        ReactGa.initialize('UA-212298576-1')
        ReactGa.pageview(window.location.pathname)
        cookies.set('A1_04', window.location.pathname);
      }, [])
      const [t] = useTranslation("global");
    return (
        <div className="Pages">
            <h2>{t('header.The panel is in an emergency state. The panel doesnt have any electricity and the battery doesnt have any charge left. To disable the alarm, you will need to either get a new power source (replace battery or power the unit) or to disable the system. What do you prefer to do?')}</h2>
            <div className="imagesPages"><img src={ImageTest} alt="imageTest"/></div>
            <div className="bannerPages"> 
                <NavBtnPages to='/GetANewPowerSource/getNewBattery'>{t('header.Get a new power source')}</NavBtnPages>
                <NavBtnPages to='/DisableTheSystem/openControlpanel2'>{t('header.Disable the system')}</NavBtnPages>
            </div>
        </div>
    )
}
export default PanelEmergencyState