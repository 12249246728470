import '../../components/NavBar/Style.css';
import React, { Component } from 'react';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
   
class Lavel2 extends Component {
    state = {
        firstname: cookies.get('Prenom'),
        lastname: cookies.get('Nom'),
        telephone: cookies.get('Téléphone'),
        email: cookies.get('Email'),
        address: cookies.get('Adresse'),
        city: cookies.get('Ville'),
        postalcode: cookies.get('Code postal'),
        province: cookies.get('Province'),
        serialnumber: cookies.get('Numero de série'),
        typeofuser: cookies.get('Type utilisateur'),
        history://Aqua-Protect:
            //My Aqua-Protec system is buzzing / audible alarm:
            cookies.get("A1") +    
            cookies.get("A1_01") + cookies.get("A1_02") + cookies.get("A1_03") + cookies.get("A1_04") + 
            cookies.get("A1_05") + cookies.get("A1_06") + cookies.get("A1_07") + cookies.get("A1_08") +
            cookies.get("A1_09") + cookies.get("A1_10") + cookies.get("A1_11") + cookies.get("A1_12") +
            cookies.get("A1_13") + cookies.get("A1_14") +
            //A red light is on:
            cookies.get("A2") + 
            //A red and a yellow lights are on:
            cookies.get("A3") + 
            //There is no lights at all: 
            cookies.get("A4") + cookies.get("A4_00") +
            cookies.get("A4_01") + cookies.get("A4_02") + cookies.get("A4_03") + cookies.get("A4_04") + 
            cookies.get("A4_05") + cookies.get("A4_06") + cookies.get("A4_07") + cookies.get("A4_08") + 
            cookies.get("A4_09") + cookies.get("A4_10") + cookies.get("A4_11") + cookies.get("A4_12") + 
            cookies.get("A4_13") + cookies.get("A4_14") +  cookies.get("A4_15") + cookies.get("A4_16") +
            cookies.get("A4_17") + cookies.get("A4_18") + cookies.get("A4_19") + cookies.get("A4_20") +
            cookies.get("A4_21") + cookies.get("A4_22") + cookies.get("A4_23") + cookies.get("A4_24") +
            cookies.get("A4_25") + 
            //My Aqua-Protect is inflated and ther is no rain outside:
            cookies.get("A5") +
            cookies.get("A5_01") + cookies.get("A5_02") + cookies.get("A5_03") + cookies.get("A5_04") + 
            cookies.get("A5_05") + cookies.get("A5_06") + cookies.get("A5_07") + cookies.get("A5_08") +
            cookies.get("A5_09") + cookies.get("A5_10") + cookies.get("A5_11") + cookies.get("A5_12") +
            cookies.get("A5_13") + cookies.get("A5_14") + cookies.get("A5_15") + 
            //There is a lot of rain outside and my Aqua-Protec is not inflating
            cookies.get("A6") +
            cookies.get("A6_1") + cookies.get("A6_2") +
            //My Aqua-Protec system didn't inflate and I had a sewer backup:
            cookies.get("A7") +
            //I have an error code shown on my screen:
            cookies.get("A8") +
            cookies.get("A8_01") + cookies.get("A8_02") + cookies.get("A8_03") + cookies.get("A8_04") + 
            cookies.get("A8_08") + cookies.get("A8_09") + cookies.get("A8_10") +  cookies.get("A8_11") + 
            cookies.get("A8_12") + cookies.get("A8_13") + cookies.get("A8_14") +  cookies.get("A8_15") +
            //Done:
            cookies.get("P1") + 
            //Thanks:
            cookies.get("T1") +
            //**********************************************************************************************************************************************************************
            //Nowa360:
            //I have no water / The valve is shut
            cookies.get("N1") +    
            cookies.get("N1_1") + cookies.get("N1_2") + cookies.get("N1_3") + cookies.get("N1_4") + 
            cookies.get("N1_5") + cookies.get("N1_6") + cookies.get("N1_7") + 
            //Issue with the valve(s)
            cookies.get("N2") +
            cookies.get("N2_1") + cookies.get("N2_2") + cookies.get("N2_3") + 
            //The App is not working
            cookies.get("N3") +
            cookies.get("N3_01") + cookies.get("N3_02") + cookies.get("N3_03") + cookies.get("N3_04") + 
            cookies.get("N3_05") + cookies.get("N3_06") + cookies.get("N3_07") + cookies.get("N3-08") +
            cookies.get("N3_09") + cookies.get("N3_10") + cookies.get("N3_11") + cookies.get("N3_12") + 
            cookies.get("N3_13") + cookies.get("N3_14") + cookies.get("N3_15") + 
            //I'm not receiving nitofications
            cookies.get("N4") +
            cookies.get("N4_01") + cookies.get("N4_02") + cookies.get("N4_03") + cookies.get("N4_04") + 
            cookies.get("N4_05") + cookies.get("N4_13") + cookies.get("N4_14") + cookies.get("N4_15") +
            cookies.get("N4_16") + cookies.get("N4_17") + cookies.get("N4_18") + cookies.get("N4_19") +
            cookies.get("N4_20") + cookies.get("N4_21") +  cookies.get("N4_22") + cookies.get("N4_23") + 
            cookies.get("N4_24") +
            //The NOWA makes a beeping noise
            cookies.get("N5") + 
            cookies.get("N5_01") + cookies.get("N5_02") + cookies.get("N5_03") + cookies.get("N5_04") + 
            cookies.get("N5_05") + cookies.get("N5_06") + cookies.get("N5_07") + cookies.get("N5_08") + 
            cookies.get("N5_09") + cookies.get("N5_10") + cookies.get("N5_11") + cookies.get("N5_12") +
            //I've detected a leak! What di I do? (Impossible de l’ordonner)
            cookies.get("N6") + 
            cookies.get("N6_01") + cookies.get("N6_02") + cookies.get("N6_03") + cookies.get("N6_04") + 
            cookies.get("N6_05") +  
            cookies.get("N6_17") + cookies.get("N6_18") + cookies.get("N6_19") + cookies.get("N6_20") +
            cookies.get("N6_21") + cookies.get("N6_22") + cookies.get("N6_23") + cookies.get("N6_24") +
            cookies.get("N6_25") + cookies.get("N6_26") + cookies.get("N6_27") + cookies.get("N6_28") +
            cookies.get("N6_29") + cookies.get("N6_30") + cookies.get("N6_31") + cookies.get("N6_32") + 
            cookies.get("N6_33") + cookies.get("N6_34") + cookies.get("N6_35") + cookies.get("N6_36") + 
            cookies.get("N6_37") + cookies.get("N6_38") + cookies.get("N6_39") + cookies.get("N6_40") +
            cookies.get("N6_41") + cookies.get("N6_42")
            ,
        emailStatus: ''
    }
    handleChange = input => e => {
        this.setState({
            [input]: e.target.value
        });
    }
    submitForm = (e) => {
        const {
            firstname,
            lastname,
            telephone,
            email,
            address,
            city,
            postalcode,
            province,
            serialnumber,
            typeofuser,
            history,
        } = this.state;
        var xhr = new XMLHttpRequest();
        xhr.addEventListener('load', () => {
            this.setState({
                emailStatus: xhr.responseText
            })
        });
        xhr.open('GET', 'https://www.troubleshooter.nowasupport.com/sendemail/index.php?sendto=' + email +
            '&firstname=' + firstname +
            '&lastname=' + lastname +
            '&telephone=' + telephone +
            '&address=' + address +
            '&city=' + city +
            '&postalcode=' + postalcode +
            '&province=' + province +
            '&serialnumber=' + serialnumber +
            '&typeofuser=' + typeofuser +
            '&history=' + history);
        xhr.send();
        e.preventDefault();
    }
    render() {
        const {
            firstname,
            lastname,
            telephone,
            email,
            address,
            city,
            postalcode,
            province,
            serialnumber,
            typeofuser,
            history,
            emailStatus
        } = this.state;
        return (
            <div className="Pages">
                <h2>Please contact Level 2 support. Don't open your valve(s) manually!</h2>
                <div className="container"
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        textAlign: 'center',
                        width: '430px',
                        height: '50px',
                        backgroundColor: "#fff",
                    }}
                >
                    <div className="h3">{emailStatus ? emailStatus : null}</div>
                    <form onSubmit={this.submitForm}>
                        <input
                            style={{
                                border: "none",
                                width: "430px",
                                height: "60px",
                                color: "#fff",
                                background: "#00B5F3",
                                fontSize: "20px"
                            }}
                            type="submit" className="button" value="Contact your installer"/>
                        <label style={{ display: 'none' }}>
                            <input className="field" type="text" placeholder="Fistname" value={firstname} onChange={this.handleChange('firstname')} />
                        </label>
                        <label style={{ display: 'none' }}>
                            <input className="field" type="text" placeholder="Lastname" value={lastname} onChange={this.handleChange('lastname')} />
                        </label>
                        <label style={{ display: 'none' }}>
                            <input className="field" type="text" placeholder="Telephone" value={telephone} onChange={this.handleChange('telephone')} />
                        </label>
                        <label style={{ display: 'none' }}>
                            <input className="field" type="text" placeholder="Email" value={email} onChange={this.handleChange('email')} />
                        </label>
                        <label style={{ display: 'none' }}>
                            <input className="field" type="text" placeholder="Address" value={address} onChange={this.handleChange('address')} />
                        </label>
                        <label style={{ display: 'none' }}>
                            <input className="field" type="text" placeholder="City" value={city} onChange={this.handleChange('city')} />
                        </label>
                        <label style={{ display: 'none' }}>
                            <input className="field" type="text" placeholder="Postalcode" value={postalcode} onChange={this.handleChange('postalcode')} />
                        </label>
                        <label style={{ display: 'none' }}>
                            <input className="field" type="text" placeholder="Province" value={province} onChange={this.handleChange('province')} />
                        </label>
                        <label style={{ display: 'none' }}>
                            <input className="field" type="text" placeholder="Serialnumber" value={serialnumber} onChange={this.handleChange('serialnumber')} />
                        </label>
                        <label style={{ display: 'none' }}>
                            <input className="field" type="text" placeholder="Typeofuser" value={typeofuser} onChange={this.handleChange('typeofuser')} />
                        </label>
                        <label style={{ display: 'none' }}>
                            <input className="field" type="text" placeholder="History" value={history} onChange={this.handleChange('history')} />
                        </label>
                    </form>
                </div>
            </div>
        )
    }
}

export default Lavel2;