import { NavBtnPages } from '../../components/NavBar/NavbarElements';
import ImageTest from '../images/ImageTest.png';
import React, {useEffect} from 'react';
import ReactGa from 'react-ga';
import Cookies from 'universal-cookie';
import {useTranslation} from 'react-i18next';

const cookies = new Cookies();

const ReturnAutomaticMode = () => {
    useEffect(() => {
        ReactGa.initialize('UA-212298576-1')
        ReactGa.pageview(window.location.pathname)
        cookies.set('A6_02', window.location.pathname);
      }, [])
      const [t] = useTranslation("global");
    return (
        <div className="Pages">
            <h2>{t('header.Press twice the checkmark / OK button on the top right corner of the control panel Go down with the down arrow until you see technician Enter the following passcode : 0084022 then press the right arrow You will be aligned with manual control, press the right arrow to select it Select deflate and confirm with the right arrow, the system should start deflating the system. After the system has deflated itself, select Return to automatic mode in the manual control menu The green light under the power icon should light up and the system will get back to a standby position Please call your installer to fix the issue as soon as possible.')}</h2>
            <div className="imagesPages"><img src={ImageTest} alt="imageTest"/></div>
            <div className="bannerPages"> 
                <NavBtnPages to='/Done/thanks' onClick={cookies.set('N8_01', '/Done/thanks')}>{t('header.Done')}</NavBtnPages>
            </div>
        </div>
    )
}
export default ReturnAutomaticMode