import { NavBtnPagesLongText } from '../../components/NavBar/NavbarElements';
import ImageTest from '../images/ImageTest.png';
import React, {useEffect} from 'react';
import ReactGa from 'react-ga';
import Cookies from 'universal-cookie';
import {useTranslation} from 'react-i18next';

const cookies = new Cookies();

const WhatIsTheIssue = () => {
    useEffect(() => {
        ReactGa.initialize('UA-212298576-1')
        ReactGa.pageview(window.location.pathname)
        cookies.set('N2', window.location.pathname);
      }, [])
      const [t] = useTranslation("global");
    return (
        <div className="Pages">
            <h2>{t('header.What seems to be the issue?')}</h2>
            <div className="imagesPages"><img src={ImageTest} alt="imageTest"/></div>
            <div className="bannerPages"> 
                <NavBtnPagesLongText to='/TheValveDoesntShutProperly/Issue1'>{t('header.The valve does not shut properly')}</NavBtnPagesLongText>
                <NavBtnPagesLongText to='/TheValveDoesntClose/Issue2'>{t('header.The valve does not close when there is a water leak')}</NavBtnPagesLongText>
                <NavBtnPagesLongText to='/WhenIPressOpenValveOrCloseValve/Issue3'>{t('header.When I press on Open valve the valve shuts and when I press on Close valve the valve opens up.')}</NavBtnPagesLongText>
            </div>
        </div>
    )
}

export default WhatIsTheIssue
