import { NavBtnPages } from '../../components/NavBar/NavbarElements';
import ImageTest from '../images/ImageTest.png';
import React, {useEffect} from 'react';
import ReactGa from 'react-ga';
import Cookies from 'universal-cookie';
import {useTranslation} from 'react-i18next';

const cookies = new Cookies();

const BlackCableKinded = () => {
    useEffect(() => {
        ReactGa.initialize('UA-212298576-1')
        ReactGa.pageview(window.location.pathname)
        cookies.set('A8_03', window.location.pathname);
      }, [])
      const [t] = useTranslation("global");
    return (
        <div className="Pages">
            <h2>{t('header.Is the black cable kinked, pinched, folded at any point in between the control panel and the clean-out cover?')}</h2>
            <div className="imagesPages"><img src={ImageTest} alt="imageTest"/></div>
            <div className="bannerPages"> 
                <NavBtnPages to='/Yes/tryReformCable'>{t('header.Yes')}</NavBtnPages>
                <NavBtnPages to='/No/performFullTest'>{t('header.No')}</NavBtnPages>
            </div>
            <div className="bannerPages"> 
            </div>
        </div>
    )
}
export default BlackCableKinded