import '../../components/NavBar/StyleWaitAMinute.css';
import {useTranslation} from 'react-i18next';

function DisplayComponent(props){
    const [t] = useTranslation("global");
    const h = () => {
        if(props.time.h === 0){
            return '';
        }else {
            return <span>{(props.time.h >= 10)? props.time.h : "0" + props.time.h}</span>;
        }
    }
    return(
        <div className="dots">
            {h()}&nbsp;&nbsp;
            <span>{(props.time.m >= 10)? props.time.m : "0" + props.time.m}</span>&nbsp;:&nbsp;
            <span>{(props.time.s >= 10)? props.time.s : "0" + props.time.s}</span>&nbsp;:&nbsp;
            <span>{(props.time.ms >= 10)? props.time.ms : "0" + props.time.ms}</span>&nbsp;&nbsp;
            <h6>{t('header.Minuts')} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
            {t('header.Seconds')} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;  
            {t('header.Milisec')}</h6>
        </div>
       
    );
}
export default DisplayComponent;