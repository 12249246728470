import { NavBtnPages } from '../../components/NavBar/NavbarElements';
import ImageTest from '../images/ImageTest.png';
import React, {useEffect} from 'react';
import ReactGa from 'react-ga';
import Cookies from 'universal-cookie';
import {useTranslation} from 'react-i18next';

const cookies = new Cookies();

const PressTwiceCheckmark = () => {
    useEffect(() => {
        ReactGa.initialize('UA-212298576-1')
        ReactGa.pageview(window.location.pathname)
        cookies.set('A6_01', window.location.pathname);
      }, [])
      const [t] = useTranslation("global");
    return (
        <div className="Pages">
            <h2>{t('header.Press twice the checkmark / OK button on the top right corner of the control panel Go down with the down arrow until you see technician Enter the following passcode : 0084022 then press the right arrow You will be aligned with manual control, press the right arrow to select it Select inflate and confirm with the right arrow, the system should start inflating the system. The system will be kept inflated until the manual control is deactivated')}</h2>
            <div className="imagesPages"><img src={ImageTest} alt="imageTest"/></div>
            <div className="bannerPages"> 
                <NavBtnPages to='/Done/thanks' onClick={cookies.set('N8_01', '/Done/thanks')}>{t('header.Done')}</NavBtnPages>
                <NavBtnPages to='/IWantToDeflateIt/returnAutomaticMode'>{t('header.I want to deflate it')}</NavBtnPages>
            </div>
        </div>
    )
}
export default PressTwiceCheckmark