import { NavBtnPages } from '../../components/NavBar/NavbarElements';
import ImageTest from '../images/ImageTest.png';
import React, {useEffect} from 'react';
import ReactGa from 'react-ga';
import Cookies from 'universal-cookie';
import {useTranslation} from 'react-i18next';

const cookies = new Cookies();

const BeCarefull = () => {
    useEffect(() => {
        ReactGa.initialize('UA-212298576-1')
        ReactGa.pageview(window.location.pathname)
        cookies.set('A5_10', window.location.pathname);
      }, [])
      const [t] = useTranslation("global");
    return (
        <div className="Pages">
            <h2>{t('header.Be carefull, deflating manually the system will enter an event in the log that will show the action in case a problem happens. Deflating the bladder should never be necessary and may cause damage if a real sewer backup is occuring. Do you still want to deflate manually the Aqua-Protec system even though it will removed any protection warranty?')}</h2>
            <div className="imagesPages"><img src={ImageTest} alt="imageTest"/></div>
            <div className="bannerPages"> 
                <NavBtnPages to='/YesIWantToDeflateIt/followProcedure'>{t('header.Yes I want to deflate it')}</NavBtnPages>
                <NavBtnPages to='/NoIWillWait/Thanks' onClick={cookies.set('N8_01', '/NoIWillWait/Thanks')}>{t('header.No, I will wait')}</NavBtnPages>
            </div>
        </div>
    )
}
export default BeCarefull