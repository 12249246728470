import { NavBtnPages } from '../../components/NavBar/NavbarElements';
import ImageTest from '../images/ImageTest.png';
import React, {useEffect} from 'react';
import ReactGa from 'react-ga';
import Cookies from 'universal-cookie';
import {useTranslation} from 'react-i18next';

const cookies = new Cookies();

const WaterSensors = () => {
    useEffect(() => {
        ReactGa.initialize('UA-212298576-1')
        ReactGa.pageview(window.location.pathname)
        cookies.set('A5_13', window.location.pathname);
      }, [])
      const [t] = useTranslation("global");
    return (
        <div className="Pages">
            <h2>{t('header.The water sensors are still detecting somethng. You may disable completely the system by following this procedure or you can call your installer to fix the problem (bladder maintenance)')}</h2>
            <div className="imagesPages"><img src={ImageTest} alt="imageTest"/></div>
            <div className="bannerPages"> 
                <NavBtnPages to='/IWantToDisableTheSystem/manualDeflation'>{t('header.I want to disable the system')}</NavBtnPages>
                <NavBtnPages to='/IWillContactMyInstaller/done'>{t('header.I will contact my installer')}</NavBtnPages>
            </div>
        </div>
    )
}
export default WaterSensors