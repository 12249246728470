import { NavBtnPages } from '../../components/NavBar/NavbarElements';
import React, { useEffect, useState } from 'react';
import ReactGa from 'react-ga';
import Cookies from 'universal-cookie';
import '../../components/NavBar/StyleTimer.css';
import DisplayComponent from './DisplayComponents';
import BtnComponent from './BtnComponent';
import '../../components/NavBar/StyleWaitAMinute.css';
import {useTranslation} from 'react-i18next';

const cookies = new Cookies();

const WaitAMinute = () => {
    useEffect(() => {
        ReactGa.initialize('UA-212298576-1')
        ReactGa.pageview(window.location.pathname)
        cookies.set('N6_22', window.location.pathname);
    }, [])
    const [t] = useTranslation("global");

    const [time, setTime] = useState({ ms: 0, s: 0, m: 0, h: 0 });
    const [interv, setInterv] = useState();
    const [status, setStatus] = useState(0);

    const start = () => {
        run();
        setStatus(1);
        setInterv(setInterval(run, 10));
    }

    var updatedMs = time.ms, updatedS = time.s, updatedM = time.m, updatedH = time.h;

    const run = () => {
        if (updatedM === 60) {
            updatedH++;
            updatedM = 0;
        }
        if (updatedS === 60) {
            updatedM++;
            updatedS = 0;
        }
    
        if (updatedMs === 100) {
            updatedS++;
            updatedMs = 0;
        }
        updatedMs++;
        return setTime({ms:updatedMs, s:updatedS, m:updatedM, h:updatedH});
    };

    const stop = () => {
        clearInterval(interv);
        setStatus(2);
    }

    const reset = () => {
        clearInterval(interv);
        setStatus(0);
        setTime({ms:0, s:0, m:0, h:0})
    }

    const resume = () => start();

    return (
        <div className="Pages">
            <h2>{t('header.Wait a minute')}</h2>
            <div className="clock-holder">
                    <div className="stopwatch">
                        <DisplayComponent time={time}/>
                        <BtnComponent status={status} resume={resume} reset={reset} stop={stop} start={start}/>
                    </div>
                </div>
            <div className="bannerPages">
                <NavBtnPages to='/Next/doYouHaveWater'>{t('header.Next')}</NavBtnPages>
            </div>
        </div>
    )
}
export default WaitAMinute