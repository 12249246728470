import { NavBtnPages } from '../../components/NavBar/NavbarElements';
import ImageTest from '../images/ImageTest.png';
import React, {useEffect} from 'react';
import ReactGa from 'react-ga';
import Cookies from 'universal-cookie';
import {useTranslation} from 'react-i18next';

const cookies = new Cookies();

const ProtectedSite = () => {
    useEffect(() => {
        ReactGa.initialize('UA-212298576-1')
        ReactGa.pageview(window.location.pathname)
        cookies.set('N4_01', window.location.pathname);
      }, [])
      const [t] = useTranslation("global");
    return (
        <div className="Pages">
            <h2>{t('header.If you have the mobile app and you are not receiving notifications, make sure that under Sites you can see your protected site. Can you see it?')}</h2>
            <div className="imagesPages"><img src={ImageTest} alt="imageTest"/></div>
            <div className="bannerPages"> 
                <NavBtnPages to='/Yes/ProtectedLetterI'>{t('header.Yes')}</NavBtnPages>
                <NavBtnPages to='/No/OpenApplication'>{t('header.No')}</NavBtnPages>
            </div>
        </div>
    )
}
export default ProtectedSite