import Pdf from '../Pdf/test.pdf';
import ImageTest from '../images/Pdf.png';
import React, {useEffect} from 'react';
import ReactGa from 'react-ga';
import Cookies from 'universal-cookie';
import {useTranslation} from 'react-i18next';

const cookies = new Cookies();

const CommunicationProblem = () => {
    useEffect(() => {
        ReactGa.initialize('UA-212298576-1')
        ReactGa.pageview(window.location.pathname)
        cookies.set('N5_09', window.location.pathname);
      }, [])
      const [t] = useTranslation("global");
    return (
        <div className="Pages">
            <h2>{t('header.Internet communication problem for sending notifications and remote control. Follow the instructions to correct the problem.')}</h2>
            <div className="imagesPages"><a href = {Pdf} target="_blank" rel="noreferrer"><img src={ImageTest} alt="imageTest"/></a></div>
        </div>
    )
}
export default CommunicationProblem