import Footer from '../components/NavBar/Footer';
import {Nav, NavBtn, NavBtnLink} from '../components/NavBar/NavbarElements';
import '../components/NavBar/Style.css';
import ImageTest from './images/ImageTest.png';
import React, {useEffect} from 'react';
import ReactGa from 'react-ga';
import {useTranslation} from 'react-i18next';

const HomeAquaProtect = () => {
    useEffect(() => {
        ReactGa.initialize('UA-212298576-1')
        ReactGa.pageview(window.location.pathname)
      }, [])
      const [t] = useTranslation("global");
    return (       
        <div>
            <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center',
            alignItems: 'top', height: '0', padding: '20px'}}>
            <h2>{t('header.What is the problem you are experiencing ?')}</h2>
            </div>
            <>
            <Nav>       
                <NavBtn>
                    <NavBtnLink to='/AquaProtect/SystemIsBuzzing/muteAlarm'>{t('header.My Aqua-Protec system is buzzing / audible alarm')}<div className="imagesHome"><img src={ImageTest} alt='ImageTest'/></div></NavBtnLink>
                </NavBtn>
                <NavBtn>
                    <NavBtnLink to='/AquaProtect/RedLightIsOn/thisMeans'>{t('header.A red light is on')}<div className="imagesHome"><img src={ImageTest} alt='ImageTest'/></div></NavBtnLink>
                </NavBtn>
                <NavBtn>
                    <NavBtnLink to='/AquaProtect/RedAndYellowLightsAreOn/itSeems'>{t('header.A red and a yellow lights are on')}<div className="imagesHome"><img src={ImageTest} alt='ImageTest'/></div></NavBtnLink>
                </NavBtn>
                <NavBtn>
                    <NavBtnLink to='/AquaProtect/ThereIsNoLightsAtAll/weeklyTestProgrammed'>{t('header.There is no lights at all')}<div className="imagesHome"><img src={ImageTest} alt='ImageTest'/></div></NavBtnLink>
                </NavBtn> 
                <NavBtn>
                    <NavBtnLink to='/AquaProtect/IsInflatedAndThereIsNoRainOutside/detectsAbnormalLevel'>{t('header.My Aqua-Protec is inflated and there is no rain outside')}<div className="imagesHome"><img src={ImageTest} alt='ImageTest'/></div></NavBtnLink>
                </NavBtn>
                <NavBtn>
                    <NavBtnLink to='/AquaProtect/ThereAreRainOutsideButNotInflated/followProcedureManually'>{t('header.There is a lot of rain outside and my Aqua-Protec is not inflating')}<div className="imagesHome"><img src={ImageTest} alt='ImageTest'/></div></NavBtnLink>
                </NavBtn>  
                <NavBtn>
                    <NavBtnLink to='/AquaProtect/SystemDidNotInflateAndIhadSewerBackup/unfortunateEvent'>{t('header.My Aqua-Protec system did not inflate and I had a sewer backup')}<div className="imagesHome"><img src={ImageTest} alt='ImageTest'/></div></NavBtnLink>
                </NavBtn>
                <NavBtn>
                    <NavBtnLink to='/AquaProtect/ErrorCodeShownOnMyScreen/whatsErrorCode'>{t('header.I have an error code shown on my screen')}<div className="imagesHome"><img src={ImageTest} alt='ImageTest'/></div></NavBtnLink>
                </NavBtn>
            </Nav>
            </>
            <Footer />
        </div>
        
    )
}

export default HomeAquaProtect