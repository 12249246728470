import { NavBtnPages } from '../../components/NavBar/NavbarElements';
import App from '../images/App.png';
import React, {useEffect} from 'react';
import ReactGa from 'react-ga';
import Cookies from 'universal-cookie';
import {useTranslation} from 'react-i18next';

const cookies = new Cookies();

const EnterInformations = () => {
    useEffect(() => {
        ReactGa.initialize('UA-212298576-1')
        ReactGa.pageview(window.location.pathname)
        cookies.set('N4_20', window.location.pathname);
      }, [])
      const [t] = useTranslation("global");
    return (
            <div className="Pages">
                <h2>{t('header.Once you clicked on add, enter the informations in the fields. If you are an installer, make sure to use installer as a username. If you are not an installer, use User or user2 where its asked to enter a username.Also make sure that the NIP used matches the username that you used. Make sure that if you use User that the letter u is in capital.')}</h2>
                <div className="imagesPages"><img src={App} alt='app'/></div>
                <div className="bannerPages"> 
                <NavBtnPages to='/Done/pressNotifications'>{t('header.Done')}</NavBtnPages>
                </div>
            </div>
    )
}
export default EnterInformations