import { NavBtnPages } from '../../components/NavBar/NavbarElements';
import App from '../images/App.png';
import React, {useEffect} from 'react';
import ReactGa from 'react-ga';
import Cookies from 'universal-cookie';
import {useTranslation} from 'react-i18next';

const cookies = new Cookies();

const ContactYourInstaller = () => {
    useEffect(() => {
        ReactGa.initialize('UA-212298576-1')
        ReactGa.pageview(window.location.pathname)
        cookies.set('N3_07', window.location.pathname);
      }, [])
      const [t] = useTranslation("global");
    return (
        <div className="Pages">
            <h2>{t('header.Please contact your installer. He will be able to tell you where the valve is located and how to solve your problem.')}</h2>
            <div className="imagesPages"><img src={App} alt="app"/></div>
            <div className="bannerPages"> 
                <NavBtnPages to='/Ok/level2' onClick={cookies.set('N9_01', '/Ok/level2')}>{t('header.Ok')}</NavBtnPages>
                <NavBtnPages to='/IHaveProblem/level2' onClick={cookies.set('N9_01', '/IHaveProblem/level2')}>{t('header.I have problem')}</NavBtnPages>
                <NavBtnPages to='/IFoundIt/SelectValveType'>{t('header.I found it')}</NavBtnPages>
            </div>
        </div>
    )
}
export default ContactYourInstaller