import Pdf from '../Pdf/test.pdf';
import ImageTest from '../images/Pdf.png';
import React, {useEffect} from 'react';
import ReactGa from 'react-ga';
import Cookies from 'universal-cookie';
import {useTranslation} from 'react-i18next';

const cookies = new Cookies();

const NoSignal = () => {
    useEffect(() => {
        ReactGa.initialize('UA-212298576-1')
        ReactGa.pageview(window.location.pathname)
        cookies.set('N5_06', window.location.pathname);
      }, [])
      const [t] = useTranslation("global");
    return (
        <div className="Pages">
            <h2>{t('header.Via the control panel, press the Status button to listen to the systems problem. What does it say?')}</h2>
            <div className="imagesPages"><a href = {Pdf} target="_blank" rel="noreferrer"><img src={ImageTest} alt="imageTest"/></a></div>
          
        </div>
    )
}
export default NoSignal