import { NavBtnPages } from '../../components/NavBar/NavbarElements';
import ImageTest from '../images/ImageTest.png';
import React, {useEffect} from 'react';
import ReactGa from 'react-ga';
import Cookies from 'universal-cookie';
import {useTranslation} from 'react-i18next';

const cookies = new Cookies();

const ColorStatusButton = () => {
    useEffect(() => {
        ReactGa.initialize('UA-212298576-1')
        ReactGa.pageview(window.location.pathname)
        cookies.set('N6_24', window.location.pathname);
      }, [])
      const [t] = useTranslation("global");
    return (
        <div className="Pages">
            <h2>{t('header.What is the color of the (I) Status button?')}</h2>
            <div className="imagesPages"><img src={ImageTest} alt="imageTest"/></div>
            <div className="bannerPages"> 
                <NavBtnPages to='/Green/PressCloseValve'>{t('header.Green')}</NavBtnPages>
                <NavBtnPages to='/Red/PressStatusButton'>{t('header.Red')}</NavBtnPages>
                <NavBtnPages to='/Gray/PowerOutage'>{t('header.(Gray) Light is off')}</NavBtnPages>
            </div>
        </div>
    )
}
export default ColorStatusButton