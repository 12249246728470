import { NavBtnPages } from '../../components/NavBar/NavbarElements';
import ImageTest from '../images/ImageTest.png';
import React, { useEffect } from 'react';
import ReactGa from 'react-ga';
import Cookies from 'universal-cookie';
import {useTranslation} from 'react-i18next';

const cookies = new Cookies();

const SystemShutDown = () => {
    useEffect(() => {
        ReactGa.initialize('UA-212298576-1')
        ReactGa.pageview(window.location.pathname)
        cookies.set('N5_12', window.location.pathname);
    }, [])
    const [t] = useTranslation("global");
    return (
        <div className="Pages">
            <h2>{t('header.The system completely shut down and when it was replenished, it could not get its date and time online beceause the system is not connected to internet. To fix this problem please follow these steps to connect your system to the internet. If you dont have access to internet connection please call us.')}</h2>
            <div className="imagesPages"><img src={ImageTest} alt="imageTest" /></div>
        <div className="bannerPages"> 
            <NavBtnPages to='/Ok/level2' onClick={cookies.set('N9_01', '/Ok/level2')}>{t('header.Ok')}</NavBtnPages> 
        </div>
        </div>
    )
}
export default SystemShutDown