import Footer from '../components/NavBar/Footer';
import {Nav, NavBtn, NavBtnLink} from '../components/NavBar/NavbarElements';
import '../components/NavBar/Style.css';
import Leak from './images/Leak.png';
import NoWater from './images/NoWater.png';
import Valve from './images/Valve.png';
import AppI from './images/App.png';
import Notifications from './images/Notifications.png';
import Panel from './images/Panel.png';
import {useTranslation} from 'react-i18next';
import React, {useEffect} from 'react';
import ReactGa from 'react-ga';

const HomeNowa360 = () => {
    useEffect(() => {
        ReactGa.initialize('UA-212298576-1')
        ReactGa.pageview(window.location.pathname)
      }, [])
    const [t] = useTranslation("global");
    return (       
        <div>
            <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center',
        alignItems: 'top', height: '0', padding: '20px'}}>
            <h2>{t('header.What is the problem you are experiencing ?')}</h2>
            </div>
            <>
            <Nav>       
                <NavBtn>
                    <NavBtnLink to='/Nowa360/IHaveNoWater/doYouHaveAccess'>{t("header.I have no water / The valve is shut")}<div className="imagesHome"><img src={NoWater} alt='Notifications'/></div></NavBtnLink>
                </NavBtn>
                <NavBtn>
                    <NavBtnLink to='/Nowa360/IssueWithTheValve/whatIsTheIssue'>{t("header.Issue with the valve(s)")}<div className="imagesHome"><img src={Valve} alt='Valve'/></div></NavBtnLink>
                </NavBtn>
                <NavBtn>
                    <NavBtnLink to='/Nowa360/TheAppIsNotWorking/doYouHaveWifi'>{t("header.The App is not working")}<div className="imagesHome"><img src={AppI} alt='App'/></div></NavBtnLink>
                </NavBtn>
                <NavBtn>
                    <NavBtnLink to='/Nowa360/IamNotReceivingNotifications/doYouHaveMobileApp' >{t("header.I'm not receiving nitofications")}<div className="imagesHome"><img src={Notifications} alt='Notifications'/></div></NavBtnLink>
                </NavBtn> 
                <NavBtn>
                    <NavBtnLink to='/Nowa360/TheNowaMakesBeepingNoise/pressStatusToListen'>{t("header.The NOWA makes a beeping noise")}<div className="imagesHome"><img src={Panel} alt='Panel'/></div></NavBtnLink>
                </NavBtn>
                <NavBtn>
                    <NavBtnLink to='/Nowa360/IDetectedLeak/findAndFixLeak' >{t("header.I've detected a leak! What do I do?")}<div className="imagesHome"><img src={Leak} alt='Leak'/></div></NavBtnLink>
                </NavBtn>  
            </Nav>
            </>
            <div className="bannerPages"></div>
            <Footer />
        </div>
        
    )
}

export default HomeNowa360