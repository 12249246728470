import { NavBtnPages } from '../../components/NavBar/NavbarElements';
import ImageTest from '../images/ImageTest.png';
import React, {useEffect} from 'react';
import ReactGa from 'react-ga';
import Cookies from 'universal-cookie';
import {useTranslation} from 'react-i18next';

const cookies = new Cookies();

const FollowThisProcedure = () => {
    useEffect(() => {
        ReactGa.initialize('UA-212298576-1')
        ReactGa.pageview(window.location.pathname)
        cookies.set('N6_20', window.location.pathname);
      }, [])
      const [t] = useTranslation("global");
    return (
        <div className="Pages">
            <h2>{t('header.Follow this procedure: [Menu] [8] (Panel will say Enter the code followed by enter) [9] [9] [9] [9] [Enter] [1] [1] (To set the time) Type the current hour (1 - 12) [Enter] Type the current minute (1 - 59) [Enter] For AM, Press [1] For PM, Press [2] [2] (To set the date) Type the day value (1 - 31) [Enter] Type the month value (1 - 12) [Enter] Type the four digit year value (ex: [2019]) [Enter][Menu][Menu][Menu]')}</h2>
            <div className="imagesPages"><img src={ImageTest} alt="imageTest"/></div>
            <div className="bannerPages"> 
                <NavBtnPages to='/Done/PressOpenValve'>{t('header.Done')}</NavBtnPages>
            </div>
        </div>
    )
}
export default FollowThisProcedure