import { NavBtnPages } from '../../components/NavBar/NavbarElements';
import ImageTest from '../images/ImageTest.png';
import React, {useEffect} from 'react';
import ReactGa from 'react-ga';
import Cookies from 'universal-cookie';
import {useTranslation} from 'react-i18next';

const cookies = new Cookies();

const WhatsErrorCode = () => {
    useEffect(() => {
        ReactGa.initialize('UA-212298576-1')
        ReactGa.pageview(window.location.pathname)
        cookies.set('A8', window.location.pathname);
      }, [])
      const [t] = useTranslation("global");
    return (
        <div className="Pages">
            <h2>{t('header.What is the error code shown on your screen?')}</h2>
            <div className="imagesPages"><img src={ImageTest} alt="imageTest"/></div>
            <div className="bannerPages"> 
                <NavBtnPages to='/Code1/manuallyResetButton'>Code 1</NavBtnPages>
                <NavBtnPages to='/Code6/systemWasNotAble'>Code 6</NavBtnPages>
                <NavBtnPages to='/Code7/systemWasNotAble'>Code 7</NavBtnPages>
                <NavBtnPages to='/Code9/bladderPressure'>Code 9</NavBtnPages>
                <NavBtnPages to='/Code20/systemWasNotAble'>Code 20</NavBtnPages>
                <NavBtnPages to='/Code21/bladderPressure'>Code 21</NavBtnPages>
                <NavBtnPages to='/Code58/installedMoreFiveYears'>Code 58</NavBtnPages>
                <NavBtnPages to='/Code67/bladderCorrectPosition'>Code 67</NavBtnPages>
            </div>
            <div className="bannerPages"> 
            </div>
        </div>
    )
}
export default WhatsErrorCode