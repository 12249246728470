import '../components/NavBar/Style.css';
import '../components/NavBar/StyleBtnHeader.css';
import '../components/NavBar/StyleForm.css'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import { useHistory } from "react-router-dom";

const cookies = new Cookies();

const LandingPage = () => {
    const [t] = useTranslation("global");
    const history = useHistory();
    const handleRoute = () => {
        history.push("/HomeSession");
    }

    return (
        <>
            <div className="Pages">
                <h4>{t('header.At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis')}</h4>
                <div className="containerForm">
                    <Formik
                        initialValues={{
                            firstname: cookies.get('Prenom'),
                            lastname: cookies.get('Nom'),
                            telephone: cookies.get('Téléphone'),
                            email: cookies.get('Email'),
                            address: cookies.get('Adresse'),
                            city: cookies.get('Ville'),
                            postalcode: cookies.get('Code postal'),
                            province: cookies.get('Province'),
                            serialnumber: cookies.get('Numero de série'),
                            typeofuser: cookies.get('Type utilisateur'),
                            picked: ''
                        }}
                        validate={(info) => {
                            let errors = {};
                            //validation first name
                            if (!info.firstname) {
                                errors.firstname = 'Please enter your first name'
                            } else if (!/^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(info.firstname)) {
                                errors.firstname = 'The name can only have letters and spaces'
                            }
                            //validation last name
                            if (!info.lastname) {
                                errors.lastname = 'Please enter your last name'
                            } else if (!/^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(info.lastname)) {
                                errors.lastname = 'The name can only have letters and spaces'
                            }
                            //validation telephone
                            if (!info.telephone) {
                                errors.telephone = 'Please enter your telephone'
                            } else if (!/^[0-9\s]+$/.test(info.telephone)) {
                                errors.telephone = 'The telephone can only have numbers and spaces'
                            }
                            //validation email
                            if (!info.email) {
                                errors.email = 'Please enter your email'
                            } else if (!/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(info.email)) {
                                errors.email = 'The email can only have letters, numbers, dots, underscores'
                            }
                            //validation addresse
                            if (!info.address) {
                                errors.address = 'Please enter your address'
                            } else if (!/^[a-zA-Z0-9-.\s]{1,40}$/.test(info.address)) {
                                errors.address = 'The address can only have letters, numbers, dots, underscores and spaces'
                            }
                            //validation ville
                            if (!info.city) {
                                errors.city = 'Please enter your city'
                            } else if (!/^[a-zA-ZÀ-ÿ_.+-\s]{1,40}$/.test(info.city)) {
                                errors.city = 'The city can only have letters and spaces'
                            }
                            //validation code postal
                            if (!info.postalcode) {
                                errors.postalcode = 'Please enter your postal code'
                            } else if (!/^[a-zA-Z0-9\s]{4,7}$/.test(info.postalcode)) {
                                errors.postalcode = 'The postal code can only have 4 to 6 letters and numbers without spaces'
                            }
                            //validation province
                            if (!info.province) {
                                errors.province = 'Please enter your province'
                            } else if (!/^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(info.province)) {
                                errors.province = 'The province can only have letters and spaces'
                            }
                            //validation serial number
                            if (!info.serialnumber) {
                            } else if (!/^[0-9\s]{1,20}$/.test(info.serialnumber)) {
                                errors.serialnumber = 'The serial number can only have numbers and spaces'
                            }
                            return errors;
                        }}
                        onSubmit={(info, { resetForm }) => {
                            handleRoute();
                            resetForm();
                            const cookies = new Cookies();
                            cookies.set('Prenom', info.firstname);
                            cookies.set('Nom', info.lastname);
                            cookies.set('Téléphone', info.telephone);
                            cookies.set('Email', info.email);
                            cookies.set('Adresse', info.address);
                            cookies.set('Ville', info.city);
                            cookies.set('Code postal', info.postalcode);
                            cookies.set('Province', info.province);
                            cookies.set('Numero de série', info.serialnumber);
                            cookies.set('Type utilisateur', info.typeofuser);
                        }}
                    >
                        {({ errors }) => (
                            <Form className="form">
                                <div>
                                    <label htmlFor="firstname"><p className="txtform">{t('header.First name')}:*</p></label><br />
                                    <Field className="field"
                                        type="text"
                                        id="firstname"
                                        name="firstname"
                                    />
                                    <ErrorMessage name="firstname" component={() => (
                                        <div className="error">{errors.firstname}</div>
                                    )} />
                                </div>

                                <div>
                                    <label htmlFor="lastname"><p className="txtform">{t('header.Last name')}:*</p></label><br />
                                    <Field className="field"
                                        type="text"
                                        id="lastname"
                                        name="lastname"
                                    />
                                    <ErrorMessage name="lastname" component={() => (
                                        <div className="error">{errors.lastname}</div>
                                    )} />
                                </div>

                                <div>
                                    <label htmlFor="telephone"><p className="txtform">{t('header.Telephone')}:*</p></label><br />
                                    <Field className="field"
                                        type="text"
                                        id="telephone"
                                        name="telephone"
                                    />
                                    <ErrorMessage name="telephone" component={() => (
                                        <div className="error">{errors.telephone}</div>
                                    )} />
                                </div>

                                <div>
                                    <label htmlFor="email"><p className="txtform">E-mail:*</p></label><br />
                                    <Field className="field"
                                        type="mail"
                                        id="email"
                                        name="email"
                                    />
                                    <ErrorMessage name="email" component={() => (
                                        <div className="error">{errors.email}</div>
                                    )} />
                                </div>

                                <div>
                                    <label htmlFor="address"><p className="txtform">{t('header.Address')}:*</p></label><br />
                                    <Field className="field"
                                        type="text"
                                        id="adress"
                                        name="address"
                                    />
                                    <ErrorMessage name="address" component={() => (
                                        <div className="error">{errors.address}</div>
                                    )} />
                                </div>

                                <div>
                                    <label htmlFor="city"><p className="txtform">{t('header.City')}:*</p></label><br />
                                    <Field className="field"
                                        type="text"
                                        id="city"
                                        name="city"
                                    />
                                    <ErrorMessage name="city" component={() => (
                                        <div className="error">{errors.city}</div>
                                    )} />
                                </div>

                                <div>
                                    <label htmlFor="postalcode"><p className="txtform">{t('header.Postal code')}:*</p></label><br />
                                    <Field className="field"
                                        type="text"
                                        id="postalcode"
                                        name="postalcode"
                                    />
                                    <ErrorMessage name="postalcode" component={() => (
                                        <div className="error">{errors.postalcode}</div>
                                    )} />
                                </div>

                                <div>
                                    <label htmlFor="province"><p className="txtform">{t('header.Province')}:*</p></label><br />
                                    <Field className="field"
                                        type="text"
                                        id="province"
                                        name="province"
                                    />
                                    <ErrorMessage name="province" component={() => (
                                        <div className="error">{errors.province}</div>
                                    )} />
                                </div>

                                <div>
                                    <label htmlFor="serialnumber"><p className="txtform">{t('header.Serial number')}:</p></label><br />
                                    <Field className="field"
                                        type="text"
                                        id="serialnumber"
                                        name="serialnumber"
                                    />
                                    <ErrorMessage name="serialnumber" component={() => (
                                        <div className="error">{errors.serialnumber}</div>
                                    )} />
                                </div>

                                <div>
                                    <label htmlFor="typeofuser"><p className="txtform">{t('header.Type of user')}:</p></label><br />
                                    <div role="group" aria-labelledby="my-radio-group" className="txtform">
                                        <label>
                                            <Field type="radio" name="typeofuser" value="User" />&nbsp;
                                            {t('header.User')}
                                        </label> &nbsp; &nbsp;
                                        <label>
                                            <Field type="radio" name="typeofuser" value="Installer" />&nbsp;
                                            {t('header.Installer')}
                                        </label> &nbsp; &nbsp;
                                        <label>
                                            <Field type="radio" name="typeofuser" value="Other" />&nbsp;
                                            {t('header.Other')}
                                        </label>
                                    </div>
                                </div>
                                <button type="submit">{t('header.Start')}</button>
                            </Form>
                        )}
                    </Formik>
                </div>
                <div className="bannerPages"></div>
                <div className="bannerPages">
                    <CookieConsent
                        style={{ background: "#00B5F3" }}
                        buttonStyle={{ color: "#000", background: "#fff", fontSize: "14px" }}>
                        {t('header.This site uses cookies to provide better results.')}
                    </CookieConsent>
                </div>
            </div>
        </>
    );
};
export default LandingPage
