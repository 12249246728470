import Pdf from '../Pdf/test.pdf';
import ImageTest from '../images/Pdf.png';
import { NavBtnPages } from '../../components/NavBar/NavbarElements';
import React, {useEffect} from 'react';
import ReactGa from 'react-ga';
import Cookies from 'universal-cookie';
import {useTranslation} from 'react-i18next';

const cookies = new Cookies();

const FollowProcedureWifi = () => {
    useEffect(() => {
        ReactGa.initialize('UA-212298576-1')
        ReactGa.pageview(window.location.pathname)
        cookies.set('N6_36', window.location.pathname);
      }, [])
      const [t] = useTranslation("global");
    return (
        <div className="Pages">
            <h2>{t('header.Follow this procedure to connect your panel to the internet via Wi-Fi.')}</h2>
            <div className="imagesPages"><a href = {Pdf} target="_blank" rel="noreferrer"><img src={ImageTest} alt="imageTest"/></a></div>
            <div className="bannerPages"> 
                <NavBtnPages to='/Done/PressOpenValve'>{t('header.Done')}</NavBtnPages>
            </div>
        </div>
    )
}
export default FollowProcedureWifi