import { NavBtnPages } from '../../components/NavBar/NavbarElements';
import ImageTest from '../images/ImageTest.png';
import React, {useEffect} from 'react';
import ReactGa from 'react-ga';
import Cookies from 'universal-cookie';
import {useTranslation} from 'react-i18next';

const cookies = new Cookies();

const RemoveMetalBracket = () => {
    useEffect(() => {
        ReactGa.initialize('UA-212298576-1')
        ReactGa.pageview(window.location.pathname)
        cookies.set('A1_08', window.location.pathname);
      }, [])
      const [t] = useTranslation("global");
    return (
        <div className="Pages">
            <h2>{t('header.Remove the metal bracket (if there is one) on top of the battery to do so, unscrew the flat head screw located above the battery Unscrew the phillips screw located under the battery. You may hold your finger underneatht to prevent the screw from rotating on itself')}</h2>
            <div className="imagesPages"><img src={ImageTest} alt="imageTest"/></div>
            <div className="bannerPages"> 
                <NavBtnPages to='/Done/removeOldBattery'>{t('header.Done')}</NavBtnPages>
            </div>
        </div>
    )
}
export default RemoveMetalBracket