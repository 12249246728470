import { NavBtnPages } from '../../components/NavBar/NavbarElements';
import ImageTest from '../images/ImageTest.png';
import React, {useEffect} from 'react';
import ReactGa from 'react-ga';
import Cookies from 'universal-cookie';
import {useTranslation} from 'react-i18next';

const cookies = new Cookies();

const DoYouKnowWhereIsTheValve = () => {
    useEffect(() => {
        ReactGa.initialize('UA-212298576-1')
        ReactGa.pageview(window.location.pathname)
        cookies.set('N3_05', window.location.pathname);
      }, [])
      const [t] = useTranslation("global");
    return (
        <div className="Pages">
            <h2>{t('header.Do you know where the valve(s) is(are) located in your home?')}</h2>
            <div className="imagesPages"><img src={ImageTest} alt="imageTest"/></div>
            <div className="bannerPages"> 
                <NavBtnPages to='/Yes/SelectValveType'>{t('header.Yes')}</NavBtnPages>
                <NavBtnPages to='/No/CanYouFindIt'>{t('header.No')}</NavBtnPages>
            </div>
        </div>
    )
}
export default DoYouKnowWhereIsTheValve