import '../../components/NavBar/Style.css';
import { useEffect, setState } from 'react';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const Lavel2 = () => {
    const firstname = cookies.get('Prenom')
    const lastname = cookies.get('Nom')
    const telephone = cookies.get('Téléphone')
    const email = cookies.get('Email')
    const address = cookies.get('Adresse')
    const city = cookies.get('Ville')
    const postalcode = cookies.get('Code postal')
    const province = cookies.get('Province')
    const serialnumber = cookies.get('Numero de série')
    const typeofuser = cookies.get('Type utilisateur')
    const history = //Aqua-Protect:
        //My Aqua-Protec system is buzzing / audible alarm:
        cookies.get("A1") +
        cookies.get("A1_01") + cookies.get("A1_02") + cookies.get("A1_03") + cookies.get("A1_04") +
        cookies.get("A1_05") + cookies.get("A1_06") + cookies.get("A1_07") + cookies.get("A1_08") +
        cookies.get("A1_09") + cookies.get("A1_10") + cookies.get("A1_11") + cookies.get("A1_12") +
        cookies.get("A1_13") + cookies.get("A1_14") +
        //A red light is on:
        cookies.get("A2") +
        //A red and a yellow lights are on:
        cookies.get("A3") +
        //There is no lights at all: 
        cookies.get("A4") + cookies.get("A4_00") +
        cookies.get("A4_01") + cookies.get("A4_02") + cookies.get("A4_03") + cookies.get("A4_04") +
        cookies.get("A4_05") + cookies.get("A4_06") + cookies.get("A4_07") + cookies.get("A4_08") +
        cookies.get("A4_09") + cookies.get("A4_10") + cookies.get("A4_11") + cookies.get("A4_12") +
        cookies.get("A4_13") + cookies.get("A4_14") + cookies.get("A4_15") + cookies.get("A4_16") +
        cookies.get("A4_17") + cookies.get("A4_18") + cookies.get("A4_19") + cookies.get("A4_20") +
        cookies.get("A4_21") + cookies.get("A4_22") + cookies.get("A4_23") + cookies.get("A4_24") +
        cookies.get("A4_25") +
        //My Aqua-Protect is inflated and ther is no rain outside:
        cookies.get("A5") +
        cookies.get("A5_01") + cookies.get("A5_02") + cookies.get("A5_03") + cookies.get("A5_04") +
        cookies.get("A5_05") + cookies.get("A5_06") + cookies.get("A5_07") + cookies.get("A5_08") +
        cookies.get("A5_09") + cookies.get("A5_10") + cookies.get("A5_11") + cookies.get("A5_12") +
        cookies.get("A5_13") + cookies.get("A5_14") + cookies.get("A5_15") +
        //There is a lot of rain outside and my Aqua-Protec is not inflating
        cookies.get("A6") +
        cookies.get("A6_1") + cookies.get("A6_2") +
        //My Aqua-Protec system didn't inflate and I had a sewer backup:
        cookies.get("A7") +
        //I have an error code shown on my screen:
        cookies.get("A8") +
        cookies.get("A8_01") + cookies.get("A8_02") + cookies.get("A8_03") + cookies.get("A8_04") +
        cookies.get("A8_08") + cookies.get("A8_09") + cookies.get("A8_10") + cookies.get("A8_11") +
        cookies.get("A8_12") + cookies.get("A8_13") + cookies.get("A8_14") +  cookies.get("A8_15") +
        //Done:
        cookies.get("P1") +
        //Thanks:
        cookies.get("T1") +
        //**********************************************************************************************************************************************************************
        //Nowa360:
        //I have no water / The valve is shut
        cookies.get("N1") +
        cookies.get("N1_1") + cookies.get("N1_2") + cookies.get("N1_3") + cookies.get("N1_4") +
        cookies.get("N1_5") + cookies.get("N1_6") + cookies.get("N1_7") +
        //Issue with the valve(s)
        cookies.get("N2") +
        cookies.get("N2_1") + cookies.get("N2_2") + cookies.get("N2_3") +
        //The App is not working
        cookies.get("N3") +
        cookies.get("N3_01") + cookies.get("N3_02") + cookies.get("N3_03") + cookies.get("N3_04") +
        cookies.get("N3_05") + cookies.get("N3_06") + cookies.get("N3_07") + cookies.get("N3-08") +
        cookies.get("N3_09") + cookies.get("N3_10") + cookies.get("N3_11") + cookies.get("N3_12") +
        cookies.get("N3_13") + cookies.get("N3_14") + cookies.get("N3_15") +
        //I'm not receiving nitofications
        cookies.get("N4") +
        cookies.get("N4_01") + cookies.get("N4_02") + cookies.get("N4_03") + cookies.get("N4_04") +
        cookies.get("N4_05") + cookies.get("N4_13") + cookies.get("N4_14") + cookies.get("N4_15") +
        cookies.get("N4_16") + cookies.get("N4_17") + cookies.get("N4_18") + cookies.get("N4_19") +
        cookies.get("N4_20") + cookies.get("N4_21") + cookies.get("N4_22") + cookies.get("N4_23") +
        cookies.get("N4_24") +
        //The NOWA makes a beeping noise
        cookies.get("N5") +
        cookies.get("N5_01") + cookies.get("N5_02") + cookies.get("N5_03") + cookies.get("N5_04") +
        cookies.get("N5_05") + cookies.get("N5_06") + cookies.get("N5_07") + cookies.get("N5_08") +
        cookies.get("N5_09") + cookies.get("N5_10") + cookies.get("N5_11") + cookies.get("N5_12") +
        //I've detected a leak! What di I do? (Impossible de l’ordonner)
        cookies.get("N6") +
        cookies.get("N6_01") + cookies.get("N6_02") + cookies.get("N6_03") + cookies.get("N6_04") +
        cookies.get("N6_05") +
        cookies.get("N6_17") + cookies.get("N6_18") + cookies.get("N6_19") + cookies.get("N6_20") +
        cookies.get("N6_21") + cookies.get("N6_22") + cookies.get("N6_23") + cookies.get("N6_24") +
        cookies.get("N6_25") + cookies.get("N6_26") + cookies.get("N6_27") + cookies.get("N6_28") +
        cookies.get("N6_29") + cookies.get("N6_30") + cookies.get("N6_31") + cookies.get("N6_32") +
        cookies.get("N6_33") + cookies.get("N6_34") + cookies.get("N6_35") + cookies.get("N6_36") +
        cookies.get("N6_37") + cookies.get("N6_38") + cookies.get("N6_39") + cookies.get("N6_40") +
        cookies.get("N6_41") + cookies.get("N6_42")
    const emailStatus= ''
    
    const handleChange = input => e => {
        setState({
            [input]: e.target.value
        });
    }
    useEffect(() => {
        let ignore = false;
        
        if (!ignore)  submitForm()
        return () => { ignore = true; }
        },);
    const submitForm = (e) => {
      
        let xhr = new XMLHttpRequest();
        
        xhr.open('GET', 'https://www.troubleshooter.nowasupport.com/sendemail/index.php?sendto=' + email +
            '&firstname=' + firstname +
            '&lastname=' + lastname +
            '&telephone=' + telephone +
            '&address=' + address +
            '&city=' + city +
            '&postalcode=' + postalcode +
            '&province=' + province +
            '&serialnumber=' + serialnumber +
            '&typeofuser=' + typeofuser +
            '&history=' + history);   
            xhr.send();
            //e.preventDefault();
    }
    return (
        <div className="Pages">
            <h2>Un message a été envoyé automatiquement à votre installateur, 
                aucune autre action n’est requise de votre part. 
                Merci beaucoup pour l’utilisation de Nowa Toroubleshooter</h2>
            <div className="container"
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    textAlign: 'center',
                    width: '430px',
                    height: '50px',
                    backgroundColor: "#fff",
                }}
            >
                <div className="h3">{emailStatus ? emailStatus : null}</div>
                <form onSubmit={submitForm}>
                    <input
                        style={{
                            border: "none",
                            width: "430px",
                            height: "60px",
                            color: "#fff",
                            background: "#00B5F3",
                            fontSize: "20px"
                        }}
                        type="submit" className="button" value="Contact your installer" />
                    <label style={{ display: 'none' }}>
                        <input className="field" type="text" placeholder="Fistname" value={firstname} onChange={handleChange('firstname')} />
                    </label>
                    <label style={{ display: 'none' }}>
                        <input className="field" type="text" placeholder="Lastname" value={lastname} onChange={handleChange('lastname')} />
                    </label>
                    <label style={{ display: 'none' }}>
                        <input className="field" type="text" placeholder="Telephone" value={telephone} onChange={handleChange('telephone')} />
                    </label>
                    <label style={{ display: 'none' }}>
                        <input className="field" type="text" placeholder="Email" value={email} onChange={handleChange('email')} />
                    </label>
                    <label style={{ display: 'none' }}>
                        <input className="field" type="text" placeholder="Address" value={address} onChange={handleChange('address')} />
                    </label>
                    <label style={{ display: 'none' }}>
                        <input className="field" type="text" placeholder="City" value={city} onChange={handleChange('city')} />
                    </label>
                    <label style={{ display: 'none' }}>
                        <input className="field" type="text" placeholder="Postalcode" value={postalcode} onChange={handleChange('postalcode')} />
                    </label>
                    <label style={{ display: 'none' }}>
                        <input className="field" type="text" placeholder="Province" value={province} onChange={handleChange('province')} />
                    </label>
                    <label style={{ display: 'none' }}>
                        <input className="field" type="text" placeholder="Serialnumber" value={serialnumber} onChange={handleChange('serialnumber')} />
                    </label>
                    <label style={{ display: 'none' }}>
                        <input className="field" type="text" placeholder="Typeofuser" value={typeofuser} onChange={handleChange('typeofuser')} />
                    </label>
                    <label style={{ display: 'none' }}>
                        <input className="field" type="text" placeholder="History" value={history} onChange={handleChange('history')} />
                    </label>
                </form>
            </div>
        </div>
    );
}
export default Lavel2;