import { NavBtnPages } from '../../components/NavBar/NavbarElements';
import ImageTest from '../images/ImageTest.png';
import React, {useEffect} from 'react';
import ReactGa from 'react-ga';
import Cookies from 'universal-cookie';
import {useTranslation} from 'react-i18next';

const cookies = new Cookies();

const ProofSewerSealted = () => {
    useEffect(() => {
        ReactGa.initialize('UA-212298576-1')
        ReactGa.pageview(window.location.pathname)
        cookies.set('A5_06', window.location.pathname);
      }, [])
      const [t] = useTranslation("global");
    return (
        <div className="Pages">
            <h2>{t('header.If you have proof that the sewer is sealed, it may be due to the bladder being blocked inside the sewers. Please do not evacuate any water and contact your installer as soon as possble. You can also contact the emergency technical support at 1-877-287-7777 #2 #1')}</h2>
            <div className="imagesPages"><img src={ImageTest} alt="imageTest"/></div>
            <div className="bannerPages"> 
                <NavBtnPages to='/Ok/systemNeedClean'>{t('header.Ok')}</NavBtnPages>
            </div>
        </div>
    )
}
export default ProofSewerSealted