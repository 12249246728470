import './App.css';
import React, {useEffect} from 'react';
import ReactGa from 'react-ga';
import Navbar from './components/NavBar/Header';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import HomeSession from './pages/HomeSession';
import HomeNowa360 from './pages/HomeNowa360';
import HomeAquaProtect from './pages/HomeAquaProtect';
import DoYouHaveAccess from './pages/Nowa360/DoYouHaveAccess';
import WhatIsTheIssue from './pages/Nowa360/WhatIsTheIssue';
import DoYouHaveMobileApp from './pages/Nowa360/DoYouHaveMobileApp';
import PressStatusToListen from './pages/Nowa360/PressStatusToListen';
import PressOpenValve from './pages/Nowa360/PressOpenValve';
import DoYouHaveWater from './pages/Nowa360/DoYouHaveWater';
import StillanIssue from './pages/Nowa360/StillanIssue';
import DoYouHaveAppUltraSync from './pages/Nowa360/DoYouHaveAppUltraSync';
import CanYouConnect from './pages/Nowa360/CanYouConnect';
import OnceYouAreConnected from './pages/Nowa360/OnceYouAreConnected';
import DoYouHaveSerial from './pages/Nowa360/DoYouHaveSerial';
import PleaseTypeSerial from './pages/Nowa360/PleaseTypeSerial';
import Level2 from './pages/Nowa360/Level2';
import DoYouHaveWifi from './pages/Nowa360/DoYouHaveWifi';
import ThePanelRequiresInternet from './pages/Nowa360/ThePanelRequiresInternet';
import OpenValveManually from './pages/Nowa360/OpenValveManually';
import DoYouKnowWhereIsTheValve from './pages/Nowa360/DoYouKnowWhereIsTheValve';
import SelectValveType from './pages/Nowa360/SelectValveType';
import FollowProcedure1 from './pages/Nowa360/FollowProcedure1';
import FollowProcedure2 from './pages/Nowa360/FollowProcedure2';
import CanYouFindIt from './pages/Nowa360/CanYouFindIt';
import ContactYourInstaller from './pages/Nowa360/ContactYourInstaller';
import Thanks from './pages/Nowa360/Thanks';
import MakeSureIsConnected from './pages/Nowa360/MakeSureIsConnected';
import OpenSmartHome from './pages/Nowa360/OpenSmartHome';
import PressAddButton from './pages/Nowa360/PressAddButton';
import EnterInformations from './pages/Nowa360/EnterInformations';
import PressNotifications from './pages/Nowa360/PressNotifications';
import PressDone from './pages/Nowa360/PressDone';
import TapInCenter from './pages/Nowa360/TapInCenter';
import ColorStatusButton from './pages/Nowa360/ColorStatusButton';
import PressCloseValve from './pages/Nowa360/PressCloseValve';
import PressStatusButton from './pages/Nowa360/PressStatusButton';
import WhatPanelSay from './pages/Nowa360/WhatPanelSay';
import FindAndFixLeak from './pages/Nowa360/FindAndFixLeak';
import LightPanelGreen from './pages/Nowa360/LightPanelGreen';
import PutSensorBack from './pages/Nowa360/PutSensorBack';
import PressResetButton from './pages/Nowa360/PressResetButton';
import DampWaterSensor from './pages/Nowa360/DampWaterSensor';
import DrySensor from './pages/Nowa360/DrySensor';
import CheckAllSensors from './pages/Nowa360/CheckAllSensors';
import DidYouFindWater from './pages/Nowa360/DidYouFindWater';
import PleaseContactLevel2 from './pages/Nowa360/PleaseContactLevel2';
import DidPanelSaySetTime from './pages/Nowa360/DidPanelSaySetTime';
import FollowThisProcedure from './pages/Nowa360/FollowThisProcedure';
import WeRecommendInternet from './pages/Nowa360/WeRecommendInternet';
import WiredOrWifi from './pages/Nowa360/WiredOrWifi';
import FollowProcedureEthernet from './pages/Nowa360/FollowProcedureEthernet';
import FollowProcedureNoInternet from './pages/Nowa360/FollowProcedureNoInternet';
import PowerOutage from './pages/Nowa360/PowerOutage';
import FindPowerSupply from './pages/Nowa360/FindPowerSupply';
import PleaseContactMaintenance from './pages/Nowa360/PleaseContactMaintenance';
import IsItPlugged from './pages/Nowa360/IsItPlugged';
import PleasePlugIt from './pages/Nowa360/PleasePlugIt';
import VerifyBreakers from './pages/Nowa360/VerifyBreakers';
import CheckWiringConnections from './pages/Nowa360/CheckWiringConnections';
import WaitAMinute from './pages/Nowa360/WaitAMinute';
import PleaseTurnItOn from './pages/Nowa360/PleaseTurnItOn';
import DidTheBreakerTripped from './pages/Nowa360/DidTheBreakerTripped';
import PleaseContactElectrician from './pages/Nowa360/PleaseContactElectrician';
import Issue1 from './pages/Nowa360/Issue1';
import Issue2 from './pages/Nowa360/Issue2';
import Issue3 from './pages/Nowa360/Issue3';
import MobileLooksLike from './pages/Nowa360/MoblieLooksLike';
import ProtectedSite from './pages/Nowa360/ProtectedSite';
import ProtectedLetterI from './pages/Nowa360/PressLetterI';
import PressNotifications2 from './pages/Nowa360/PressNotifications2';
import PressShowEvent from './pages/Nowa360/PressShowEvent';
import PressBackTwice from './pages/Nowa360/PressBackTwice';
import OpenApplication from './pages/Nowa360/OpenApplication';
import WhichKindSmartphone from './pages/Nowa360/WhichKindSmartphone';
import PlayStore from './pages/Nowa360/PlayStore';
import DownloadUltrasync from './pages/Nowa360/DownloadUltrasync';
import OpenAppSmartHome from './pages/Nowa360/OpenAppSmartHome';
import AppStore from './pages/Nowa360/AppStore';
import WindowsStore from './pages/Nowa360/WindowsStore';
import NoSignal from './pages/Nowa360/NoSignal';
import ChangeBattery from './pages/Nowa360/ChangeBattery';
import BatterySensorX from './pages/Nowa360/BatterySensorX';
import CommunicationProblem from './pages/Nowa360/CommunicationProblem';
import SensorDetectingWater from './pages/Nowa360/SensorDetectingWater';
import PowerFailure from './pages/Nowa360/PowerFailure';
import SystemShutDown from './pages/Nowa360/SystemShutDown';
import MuteAlarm from './pages/AquaProtect/MuteAlarm';
import DidAlarmStopped from './pages/AquaProtect/DidAlarmStopped';
import LightsUnderneathLCD from './pages/AquaProtect/LightsUnderneathLCD';
import HavingPowerOutage from './pages/AquaProtect/HavingPowerOutage';
import PanelEmergencyState from './pages/AquaProtect/PanelEmergencyState';
import GetNewBattery from './pages/AquaProtect/GetNewBattery';
import UnplugWiresBattery from './pages/AquaProtect/UnplugWiresBattery';
import RemoveMetalBracket from './pages/AquaProtect/RemoveMetalBracket';
import RemoveOldBattery from './pages/AquaProtect/RemoveOldBattery';
import PlugRedWire from './pages/AquaProtect/PlugRedWire';
import PanelShouldBePowered from './pages/AquaProtect/PanelShouldBePowered';
import WeRecommandGeneralStore from './pages/AquaProtect/WeRecommandGeneralStore';
import IsPowerSupplyConnected from './pages/AquaProtect/IsPowerSupplyConnected';
import PlugBackPowerSupply from './pages/AquaProtect/PlugBackPowerSupply';
import IsBreakerInPanelOn from './pages/AquaProtect/IsBreakerInPanelOn';
import PutbackBreakerOn from './pages/AquaProtect/PutbackBreakerOn';
import IsWireRunning from './pages/AquaProtect/IsWireRunning';
import CallYourTechnician from './pages/AquaProtect/CallYourTechnician';
import UnplugBattery from './pages/AquaProtect/UnplugBattery';
import UnplugPowerSupply from './pages/AquaProtect/UnplugPowerSupply';
import PanelStoppedBuzzing from './pages/AquaProtect/PanelStoppedBuzzing';
import PowerSupplyBack from './pages/AquaProtect/PowerSupplyBack';
import UnplugOneOfTheTwo from './pages/AquaProtect/UnplugOneOfTheTwo';
import OpenControlPanel from './pages/AquaProtect/OpenControlPanel';
import BarrelConnectorConnected from './pages/AquaProtect/BarrelConnectorConnected';
import ReconnectBarrelConnector from './pages/AquaProtect/ReconnectBarrelConnector';
import GreenLightOnOff from './pages/AquaProtect/GreenLightOnOff';
import ProblemSolved from './pages/AquaProtect/ProblemSolved';
import BatteryTerminalsConnected from './pages/AquaProtect/BatteryTerminalsConnected';
import YouMayWait from './pages/AquaProtect/YouMayWait';
import ReconnectTerminal from './pages/AquaProtect/ReconnectTerminal';
import OpenControlPanel2 from './pages/AquaProtect/OpenControlPanel2';
import FindBiggerScrewdriver from './pages/AquaProtect/FindBiggerScrewdriver';
import CallYourInstaller from './pages/AquaProtect/CallYourInstaller';
import ThisMeans from './pages/AquaProtect/ThisMeans';
import ItSeems from './pages/AquaProtect/ItSeems';
import YouShouldContactInstaller from './pages/AquaProtect/YouShouldContactInstaller';
import BeCarefull from './pages/AquaProtect/BeCarefull';
import FollowProcedure from './pages/AquaProtect/FollowProcedure';
import OnceTheProcedure from './pages/AquaProtect/OnceTheProcedure';
import WaterSensors from './pages/AquaProtect/WaterSensors';
import ManualDeflation from './pages/AquaProtect/ManualDeflation';
import SystemNowDisable from './pages/AquaProtect/SystemNowDisabled';
import Done from './pages/AquaProtect/Done';
import WeeklyTestProgrammed from './pages/AquaProtect/WeeklyTestProgrammed';
import DoingWeeklyTest from './pages/AquaProtect/DoingWeeklyTest';
import TenMiuteTimer from './pages/AquaProtect/TenMinutesTimer';
import PanelBlackNormal from './pages/AquaProtect/PanelBlackNormal';
import HavingPowerOutage2 from './pages/AquaProtect/HavingPowerOutage2';
import NoMorePower from './pages/AquaProtect/NoMorePower';
import DetectsAbnormalLevel from './pages/AquaProtect/DetectsAbnormalLevel';
import DidItInflate from './pages/AquaProtect/DidItInflate';
import WhichLightsUnderneath from './pages/AquaProtect/WhichLightsUnderneath';
import GreenLightsMeans from './pages/AquaProtect/GreenLightMeans';
import ProofSewerSealted from './pages/AquaProtect/ProofSewerSealted';
import SystemNeedClean from './pages/AquaProtect/SystemNeedClean';
import SystemIsInflated from './pages/AquaProtect/SystemIsInflated';
import SystemStartSoon from './pages/AquaProtect/SystemStartSoon';
import SystemBlockingSewer from './pages/AquaProtect/SystemBlockingSewer';
import UnfortunateEvent from './pages/AquaProtect/UnfortunateEvent';
import FollowProcedureManujally from './pages/AquaProtect/FollowProcedureManually';
import PressTwiceCheckmark from './pages/AquaProtect/PressTwiceCheckmark';
import ReturnAutomaticMode from './pages/AquaProtect/ReturnAutomaticMode';
import WhatsErrorCode from './pages/AquaProtect/WhatsErrorCode';
import ManuallyResetButton from './pages/AquaProtect/ManuallyResetButton';
import BlackCableKinded from './pages/AquaProtect/BlackCableKinked';
import TryReformCable from './pages/AquaProtect/TryReformCable';
import PerformFullTest from './pages/AquaProtect/PerformFullTest';
import DidFullTestPassed from './pages/AquaProtect/DidFullTestPassed';
import ContactYourInstaller2 from './pages/AquaProtect/ContactYourInstaller2';
import ErrorCodeShow from './pages/AquaProtect/ErrorCodeShow';
import SystemWasNotAble from './pages/AquaProtect/SystemWasNotAble';
import BladderPressure from './pages/AquaProtect/BladderPressure';
import SystemShouldBeAble from './pages/AquaProtect/SystemShouldBeAble';
import Cookies from 'universal-cookie';
import InstalledMoreFiveYears from './pages/AquaProtect/InstalledMoreFiveYears';
import BladderCorrectPosition from './pages/AquaProtect/BladderCorrectPosition';
import CanYouFindSensorX from './pages/Nowa360/CanYouFindSensorX';
import SensorXIsInAlarmWaterDetected from './pages/Nowa360/SensorXIsInAlarmWaterDetected';
import WaitAMinute2 from './pages/Nowa360/WaitAMinute2';
import FollowProcedureWifi from './pages/Nowa360/FollowProcedureWifi';
import StillAnIssue2 from './pages/Nowa360/StillAnIssue2';

function App() {
  useEffect(() => {
    ReactGa.initialize('UA-212298576-1')
    ReactGa.pageview(window.location.pathname)
  }, [])
  return (
    <Router>
      <Navbar />
      <Switch>
        <Route path="/" exact component={LandingPage}/>
        <Route path="/homeSession" exact component={HomeSession}/>
        <Route path="/homeNowa360" component={HomeNowa360}/>
        <Route path="/homeAquaProtect" component={HomeAquaProtect}/>
        <Route path="/Nowa360/IHaveNoWater/doYouHaveAccess"  component={DoYouHaveAccess}/> 
        <Route path="/Nowa360/IssueWithTheValve/whatIsTheIssue"  component={WhatIsTheIssue}/>      
        <Route path="/Nowa360/IamNotReceivingNotifications/doYouHaveMobileApp"  component={DoYouHaveMobileApp}/>
        <Route path="/Ok/doYouHaveMobileApp"  component={DoYouHaveMobileApp}/>
        <Route path="/Nowa360/TheNowaMakesBeepingNoise/pressStatusToListen"  component={PressStatusToListen}/>       
        <Route path="/Yes/pressOpenValve"  component={PressOpenValve}/>
        <Route path="/Done/pressOpenValve"  component={PressOpenValve}/>
        <Route path="/Next/pressOpenValve"  component={PressOpenValve}/>
        <Route path="/Done/doYouHaveWater"  component={DoYouHaveWater}/>
        <Route path="/Next/doYouHaveWater"  component={DoYouHaveWater}/>
        <Route path="/Yes/stillanIssue"  component={StillanIssue}/>
        <Route path="/No/stillanIssue"  component={StillanIssue}/>
        <Route path="/Next/stillanIssue"  component={StillanIssue}/>
        <Route path="/Next/stillanIssue2"  component={StillAnIssue2}/>
        <Route path="/No/doYouHaveAppUltraSync"  component={DoYouHaveAppUltraSync}/>
        <Route path="/Yes/canYouConnect"  component={CanYouConnect}/>
        <Route path="/Yes/onceYouAreConnected"  component={OnceYouAreConnected}/>
        <Route path="/No/doYouHaveSerial"  component={DoYouHaveSerial}/>
        <Route path="/Yes/pleaseTypeSerial"  component={PleaseTypeSerial}/>
        <Route path="/Yes/level2"  component={Level2}/>
        <Route path="/No/level2"  component={Level2}/>
        <Route path="/Ok/level2"  component={Level2}/>
        <Route path="/Next/level2"  component={Level2}/>
        <Route path="/IHaveProblem/level2"  component={Level2}/>
        <Route path="/ItSeemsOk/level2"  component={Level2}/>
        <Route path="/ICanNot/level2"  component={Level2}/>
        <Route path="/GreenLightIsOff/level2"  component={Level2}/>
        <Route path="/ItFailed/level2"  component={Level2}/>
        <Route path="/IStillCantOpenIt/level2"  component={Level2}/>
        <Route path="/Nowa360/TheAppIsNotWorking/doYouHaveWifi"  component={DoYouHaveWifi}/>
        <Route path="/No/doYouHaveWifi"  component={DoYouHaveWifi}/>
        <Route path="/No/thePanelRequiresInternet"  component={ThePanelRequiresInternet}/>
        <Route path="/Next/openValveManually"  component={OpenValveManually}/>
        <Route path="/Yes/openValveManually"  component={OpenValveManually}/>
        <Route path="/IWantToReopenIt/doYouKnowWhereIsTheValve"  component={DoYouKnowWhereIsTheValve}/>
        <Route path="/Yes/selectValveType"  component={SelectValveType}/>
        <Route path="/IFoundIt/selectValveType"  component={SelectValveType}/>
        <Route path="/OrLess1/followProcedure1"  component={FollowProcedure1}/>
        <Route path="/MoreThan1/followProcedure2"  component={FollowProcedure2}/>
        <Route path="/No/canYouFindIt"  component={CanYouFindIt}/>
        <Route path="/IDontSeeIt/contactYourInstaller"  component={ContactYourInstaller}/>
        <Route path="/No/thanks"  component={Thanks}/>
        <Route path="/NoIWillWait/thanks"  component={Thanks}/>
        <Route path="/Yes/thanks"  component={Thanks}/>
        <Route path="/Done/thanks"  component={Thanks}/>
        <Route path="/Ok/thanks"  component={Thanks}/>
        <Route path="/IUnderstand/thanks"  component={Thanks}/>
        <Route path="/IWillWait/thanks"  component={Thanks}/>
        <Route path="/TheyAreAllOn/thanks"  component={Thanks}/>
        <Route path="/WiredInternet/makeSureIsConnected"  component={MakeSureIsConnected}/>
        <Route path="/WirelessWifi/makeSureIsConnected"  component={MakeSureIsConnected}/>
        <Route path="/CellularModule/makeSureIsConnected"  component={MakeSureIsConnected}/>
        <Route path="/No/openSmartHome"  component={OpenSmartHome}/>
        <Route path="/Done/pressAddButton"  component={PressAddButton}/>
        <Route path="/Done/enterInformations"  component={EnterInformations}/>
        <Route path="/Done/pressNotifications"  component={PressNotifications}/>
        <Route path="/Done/pressDone"  component={PressDone}/>
        <Route path="/Done/tapInCenter"  component={TapInCenter}/>
        <Route path="/No/colorStatusButton"  component={ColorStatusButton}/>
        <Route path="/Green/pressCloseValve"  component={PressCloseValve}/>
        <Route path="/Red/pressStatusButton"  component={PressStatusButton}/>
        <Route path="/Done/whatPanelSay"  component={WhatPanelSay}/>
        <Route path="/Nowa360/IDetectedLeak/findAndFixLeak"  component={FindAndFixLeak}/>
        <Route path="/Done/lightPanelGreen"  component={LightPanelGreen}/>
        <Route path="/Yes/putSensorBack"  component={PutSensorBack}/>
        <Route path="/Done/pressresetButton"  component={PressResetButton}/>
        <Route path="/No/dampWaterSensor"  component={DampWaterSensor}/>
        <Route path="/Done/drySensor"  component={DrySensor}/>
        <Route path="/No/checkAllSensors"  component={CheckAllSensors}/>
        <Route path="/Done/didYouFindWater"  component={DidYouFindWater}/>
        <Route path="/No/pleaseContactLevel2"  component={PleaseContactLevel2}/>
        <Route path="/TimeOrDataLost/didPanelSaySetTime"  component={DidPanelSaySetTime}/>
        <Route path="/Yes/followThisProcedure"  component={FollowThisProcedure}/>
        <Route path="/No/weRecommendInternet"  component={WeRecommendInternet}/>
        <Route path="/FailToCommunicate/weRecommendInternet"  component={WeRecommendInternet}/>
        <Route path="/Yes/wiredOrWifi"  component={WiredOrWifi}/>
        <Route path="/Yes/followProcedureEthernet"  component={FollowProcedureEthernet}/>
        <Route path="/No/followProcedureWifi"  component={FollowProcedureWifi}/>
        <Route path="/No/followProcedureNoInternet"  component={FollowProcedureNoInternet}/>
        <Route path="/Gray/powerOutage"  component={PowerOutage}/>
        <Route path="/No/findPowerSupply"  component={FindPowerSupply}/>
        <Route path="/No/pleaseContactMaintenance"  component={PleaseContactMaintenance}/>
        <Route path="/Yes/isItPlugged"  component={IsItPlugged}/>
        <Route path="/No/pleasePlugIt"  component={PleasePlugIt}/>
        <Route path="/Yes/verifyBreakers"  component={VerifyBreakers}/>
        <Route path="/ItsOn/checkWiringConnections"  component={CheckWiringConnections}/>
        <Route path="/Done/waitAMinute"  component={WaitAMinute}/>
        <Route path="/No/waitAMinute2"  component={WaitAMinute2}/>
        <Route path="/Done/waitAMinute2"  component={WaitAMinute2}/>
        <Route path="/ItWasDisconnected/waitAMinute2"  component={WaitAMinute2}/>
        <Route path="/ItsOff/PleaseTurnItOn"  component={PleaseTurnItOn}/>
        <Route path="/Done/DidTheBreakerTripped"  component={DidTheBreakerTripped}/>
        <Route path="/Yes/pleaseContactElectrician"  component={PleaseContactElectrician}/>
        <Route path="/TheValveDoesntShutProperly/issue1"  component={Issue1}/>
        <Route path="/TheValveDoesntClose/issue2"  component={Issue2}/>
        <Route path="/WhenIPressOpenValveOrCloseValve/issue3"  component={Issue3}/>
        <Route path="/WhatDoesItLookLike/mobileLooksLike"  component={MobileLooksLike}/>
        <Route path="/Yes/protectedSite"  component={ProtectedSite}/>
        <Route path="/Yes/protectedLetterI"  component={ProtectedLetterI}/>
        <Route path="/Done/pressNotifications2"  component={PressNotifications2}/>
        <Route path="/Done/pressShowEvent"  component={PressShowEvent}/>
        <Route path="/Done/pressBackTwice"  component={PressBackTwice}/>
        <Route path="/No/openApplication"  component={OpenApplication}/>
        <Route path="/No/whichKindSmartphone"  component={WhichKindSmartphone}/>
        <Route path="/Android/playStore"  component={PlayStore}/>
        <Route path="/Done/downloadUltrasync"  component={DownloadUltrasync}/>
        <Route path="/Done/openAppSmartHome"  component={OpenAppSmartHome}/>
        <Route path="/Apple/appStore"  component={AppStore}/>
        <Route path="/Windows/windowsStore"  component={WindowsStore}/>
        <Route path="/LossOfSupervision/noSignal"  component={NoSignal}/>
        <Route path="/LowBattery/changeBattery"  component={ChangeBattery}/>
        <Route path="/SensorXLowBattery/batterySensorx"  component={BatterySensorX}/>
        <Route path="/WiFiFault/communicationProblem"  component={CommunicationProblem}/>
        <Route path="/SensorXIsNotReady/sensorDetectingWater"  component={SensorDetectingWater}/>
        <Route path="/ACFall/powerFailure"  component={PowerFailure}/>
        <Route path="/TimeAndDateLoss/systemShutDown"  component={SystemShutDown}/>
        <Route path="/AquaProtect/SystemIsBuzzing/muteAlarm"  component={MuteAlarm}/>
        <Route path="/Done/didAlarmStopped"  component={DidAlarmStopped}/>
        <Route path="/No/lightsUnderneathLCD"  component={LightsUnderneathLCD}/>
        <Route path="/NoTheyAreAllOff/havingPowerOutage"  component={HavingPowerOutage}/>
        <Route path="/Yes/panelEmergencyState"  component={PanelEmergencyState}/>
        <Route path="/GetANewPowerSource/getNewBattery"  component={GetNewBattery}/>
        <Route path="/IGotOne/unplugWiresBattery"  component={UnplugWiresBattery}/>
        <Route path="/Done/unplugWiresBattery"  component={UnplugWiresBattery}/>
        <Route path="/Done/removeMetalBracket"  component={RemoveMetalBracket}/>
        <Route path="/Done/removeOldBattery"  component={RemoveOldBattery}/>
        <Route path="/Done/plugRedWire"  component={PlugRedWire}/>
        <Route path="/Done/panelShouldBePowered"  component={PanelShouldBePowered}/>
        <Route path="/WhereDoIGetOne/weRecommandGeneralStore"  component={WeRecommandGeneralStore}/>
        <Route path="/No/isPowerSupplyConnected"  component={IsPowerSupplyConnected}/>
        <Route path="/YesButTheyAreDimmed/isPowerSupplyConnected"  component={IsPowerSupplyConnected}/>
        <Route path="/No/plugBackPowerSupply"  component={PlugBackPowerSupply}/>
        <Route path="/Yes/isBreakerInPanelOn"  component={IsBreakerInPanelOn}/>
        <Route path="/Done/isBreakerInPanelOn"  component={IsBreakerInPanelOn}/>
        <Route path="/No/putbackBreakerOn"  component={PutbackBreakerOn}/>
        <Route path="/Yes/isWireRunning"  component={IsWireRunning}/>
        <Route path="/Yes/callYourTechnician"  component={CallYourTechnician}/>
        <Route path="/Done/unplugBattery"  component={UnplugBattery}/>
        <Route path="/TheControlPanelIsOpen/unplugBattery"  component={UnplugBattery}/>
        <Route path="/YesIHaveFoundABiggerScrewdriver/unplugBattery"  component={UnplugBattery}/>
        <Route path="/SomeoneHelpedMeOpenedIt/unplugBattery"  component={UnplugBattery}/>
        <Route path="/SomeoneHelpedMeOpenedIt/unplugPowerSupply"  component={UnplugPowerSupply}/>
        <Route path="/Done/unplugPowerSupply"  component={UnplugPowerSupply}/>
        <Route path="/Done/panelStoppedBuzzing"  component={PanelStoppedBuzzing}/>
        <Route path="/Yes/powerSupplyBack"  component={PowerSupplyBack}/>
        <Route path="/ICantUnpluggedIt/unplugOneOfTheTwo"  component={UnplugOneOfTheTwo}/>
        <Route path="/No/openControlPanel"  component={OpenControlPanel}/>
        <Route path="/Done/barrelConnectorConnected"  component={BarrelConnectorConnected}/>
        <Route path="/No/reconnectBarrelConnector"  component={ReconnectBarrelConnector}/>
        <Route path="/Done/greenLightOnOff"  component={GreenLightOnOff}/>
        <Route path="/GreenLightIsOn/problemSolved"  component={ProblemSolved}/>
        <Route path="/GreenLightIsBlinking/batteryTerminalsConnected"  component={BatteryTerminalsConnected}/>
        <Route path="/Yes/youMayWait"  component={YouMayWait}/>
        <Route path="/No/reconnectTerminal"  component={ReconnectTerminal}/>
        <Route path="/DisableTheSystem/openControlPanel2"  component={OpenControlPanel2}/>
        <Route path="/ICantIsToHardToOpen/findBiggerScrewdriver"  component={FindBiggerScrewdriver}/>
        <Route path="/IStillCantOpenIt/callYourInstaller"  component={CallYourInstaller}/>
        <Route path="/AquaProtect/RedLightIsOn/thisMeans"  component={ThisMeans}/>
        <Route path="/AquaProtect/RedAndYellowLightsAreOn/itSeems"  component={ItSeems}/>
        <Route path="/ItsBeenALongTimeSinceItIsInflated/youShouldContactInstaller"  component={YouShouldContactInstaller}/>
        <Route path="/IWantToDeflateItManually/beCarefull"  component={BeCarefull}/>
        <Route path="/YesIWantToDeflateIt/followProcedure"  component={FollowProcedure}/>
        <Route path="/Ok/onceTheProcedure"  component={OnceTheProcedure}/>
        <Route path="/Yes/waterSensors"  component={WaterSensors}/>
        <Route path="/IWantToDisableTheSystem/manualDeflation"  component={ManualDeflation}/>
        <Route path="/Done/systemNowDisabled"  component={SystemNowDisable}/>
        <Route path="/ItPassed/done"  component={Done}/>
        <Route path="/No/done"  component={Done}/>
        <Route path="/Ok/done"  component={Done}/>
        <Route path="/IWillContactMyInstaller/done"  component={Done}/>
        <Route path="/MySystemIsInflated/done"  component={Done}/>
        <Route path="/AquaProtect/ThereIsNoLightsAtAll/weeklyTestProgrammed"  component={WeeklyTestProgrammed}/>
        <Route path="/Yes/doingWeeklyTest"  component={DoingWeeklyTest}/>
        <Route path="/ImNotSure/doingWeeklyTest"  component={DoingWeeklyTest}/>
        <Route path="/WaitForTenMiniutes/tenMinutesTimer"  component={TenMiuteTimer}/>
        <Route path="/Done/panelBlackNormal"  component={PanelBlackNormal}/>
        <Route path="/No/havingPowerOutage2"  component={HavingPowerOutage2}/>
        <Route path="/Yes/noMorePower"  component={NoMorePower}/>
        <Route path="/AquaProtect/IsInflatedAndThereIsNoRainOutside/detectsAbnormalLevel"  component={DetectsAbnormalLevel}/>
        <Route path="/Ok/didItInflate"  component={DidItInflate}/>
        <Route path="/Yes/whichLightsUnderneath"  component={WhichLightsUnderneath}/>
        <Route path="/No/whichLightsUnderneath"  component={WhichLightsUnderneath}/>
        <Route path="/GreenOnly/greenLightMeans"  component={GreenLightsMeans}/>
        <Route path="/Ok/proofSewerSealted"  component={ProofSewerSealted}/>
        <Route path="/TheSystemIsBlockingMySewerLine/proofSewerSealted"  component={ProofSewerSealted}/>
        <Route path="/Ok/systemNeedClean"  component={SystemNeedClean}/>
        <Route path="/RedOnly/systemIsInflated"  component={SystemIsInflated}/>
        <Route path="/YellowOnly/systemStartSoon"  component={SystemStartSoon}/>
        <Route path="/GreenAndYellow/systemStartSoon"  component={SystemStartSoon}/>
        <Route path="/RedAndYellow/systemBlockingSewer"  component={SystemBlockingSewer}/>
        <Route path="/AquaProtect/SystemDidNotInflateAndIhadSewerBackup/unfortunateEvent"  component={UnfortunateEvent}/>
        <Route path="/AquaProtect/ThereAreRainOutsideButNotInflated/followProcedureManually"  component={FollowProcedureManujally}/>
        <Route path="/Ok/pressTwiceCheckmark"  component={PressTwiceCheckmark}/>
        <Route path="/IWantToDeflateIt/returnAutomaticMode"  component={ReturnAutomaticMode}/>
        <Route path="/AquaProtect/ErrorCodeShownOnMyScreen/whatsErrorCode"  component={WhatsErrorCode}/>
        <Route path="/Code1/manuallyResetButton"  component={ManuallyResetButton}/>
        <Route path="/No/blackCableKinked"  component={BlackCableKinded}/>
        <Route path="/IDontKnow/blackCableKinked"  component={BlackCableKinded}/>
        <Route path="/Yes/tryReformCable"  component={TryReformCable}/>
        <Route path="/Done/performFullTest"  component={PerformFullTest}/>
        <Route path="/No/performFullTest"  component={PerformFullTest}/>
        <Route path="/Ok/performFullTest"  component={PerformFullTest}/>
        <Route path="/Done/didFullTestPassed"  component={DidFullTestPassed}/>
        <Route path="/ItFailed/contactYourInstaller2"  component={ContactYourInstaller2}/>
        <Route path="/Yes/errorCodeShow"  component={ErrorCodeShow}/>
        <Route path="/Code6/systemWasNotAble"  component={SystemWasNotAble}/>
        <Route path="/Code7/systemWasNotAble"  component={SystemWasNotAble}/>
        <Route path="/Code20/systemWasNotAble"  component={SystemWasNotAble}/>
        <Route path="/Code9/bladderPressure"  component={BladderPressure}/>
        <Route path="/Code21/bladderPressure"  component={BladderPressure}/>
        <Route path="/Ok/systemShouldBeAble"  component={SystemShouldBeAble}/>
        <Route path="/Code58/installedMoreFiveYears"  component={InstalledMoreFiveYears}/>
        <Route path="/Code67/bladderCorrectPosition"  component={BladderCorrectPosition}/>
        <Route path="/Ok/canYouFindSensorX"  component={CanYouFindSensorX}/>
        <Route path="/SensorXInAlarm/sensorXIsInAlarmWaterDetected"  component={SensorXIsInAlarmWaterDetected}/>
        <Route path="/XisInAlarm/sensorXIsInAlarmWaterDetected"  component={SensorXIsInAlarmWaterDetected}/>
      </Switch>
    </Router>
  );
}
export default App;

